import styled from "styled-components";
import { useSelector } from "react-redux";
import HomeButton from "./HomeButton";
import { Link, useNavigate } from "react-router-dom";
import HomeButtonSecondary from "./HomeButtonSecondary";

const Wrapper = styled.div`
  padding-top: 54px;
  padding-bottom: 24px;
  font-size: 20px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
`;

const SubTitle = styled.div`
  font-size: 28px;
`;

const ButtonWrapper = styled.div`
  padding-top: 14px;
  width: 100%;
  display: flex;
`;

const SubNote = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 16px;
`;

const SecondaryWrapper = styled.div`
  padding-top: 14px;
  display: ${(props) => (props.$buttonsInLine ? "flex" : "")};
`;

const SecondaryButtonWrapper = styled.div`
  padding-bottom: 12px;
  width: 352px;
`;

const TermsAgreement = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
`;

const HomeDesktop = () => {
  const navigate = useNavigate();
  const screenWidth = useSelector((state) => state.screenWidth.value);
  // determined by 24 + 24 (margin) + 340 + 340 (secondary button widths) + 12 (margin in between buttons)
  const buttonsInLine = screenWidth >= 740;

  return (
    <Wrapper>
      <Title>Verified Voice</Title>
      <SubTitle>Online petitions for registered voters.</SubTitle>
      <br />
      <br />
      Participate by signing in as a verified registered voter:
      <ButtonWrapper>
        <HomeButton
          text="SIGN IN"
          onClick={() => {
            navigate("/signin");
          }}
        />
      </ButtonWrapper>
      <SubNote>Via phone or email verification.</SubNote>
      <br />
      <br />
      <br />
      <br />
      <SecondaryWrapper $buttonsInLine={buttonsInLine}>
        <SecondaryButtonWrapper>
          <HomeButtonSecondary
            text="Browse Petitions"
            onClick={() => {
              navigate("/petitions");
            }}
          />
        </SecondaryButtonWrapper>
        <HomeButtonSecondary
          text="About Verified Voice"
          onClick={() => {
            navigate("/about");
          }}
        />
      </SecondaryWrapper>
      <TermsAgreement>
        By browsing this website or engaging with our platform, you agree to our{" "}
        <Link
          to="/about/terms-of-service"
          target="_blank"
          style={{ color: "rgba(0, 0, 0, 0.5)" }}
        >
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link
          to="/about/privacy-policy"
          target="_blank"
          style={{ color: "rgba(0, 0, 0, 0.5)" }}
        >
          Privacy Policy
        </Link>
        .
      </TermsAgreement>
    </Wrapper>
  );
};

export default HomeDesktop;
