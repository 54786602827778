import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../Redux/userInfoSlice";
import { useSelector } from "react-redux";

import { httpsCallable } from "firebase/functions";
import { onAuthStateChanged } from "firebase/auth";
import { fireDb, fireAuth, fireFunctions } from "../Firebase/firebase";
import { setUserLoading } from "../Redux/userLoadingSlice";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore/lite";
import { setMatchedAllVoters } from "../Redux/matchedAllVotersSlice";
import { setMatchedSelectedVoter } from "../Redux/matchedSelectedVoterSlice";

const userUpdateVersion = "nov_2024";

const userNeedsSetUp = async (user) => {
  let needsSetUp = true;
  const setUpDocRef = doc(fireDb, "setUpStatus", user.uid);
  const setUpDocSnap = await getDoc(setUpDocRef);
  if (setUpDocSnap.exists()) {
    const lastSetUp = setUpDocSnap.data()?.lastSetUp;
    if (lastSetUp === userUpdateVersion) {
      needsSetUp = false;
    }
  }
  return needsSetUp;
};

export const useProcessAuthChange = () => {
  const dispatch = useDispatch();
  const selectedMatchedVoter = useSelector(
    (state) => state.matchedSelectedVoter.value,
  );
  const selectedVoterId = selectedMatchedVoter?.voterId;

  // this function is responsible for setting up a given user, fetching the matched voters, and dispatching everything correctly.
  const setUpAndFetchUserVoters = async (user) => {
    // if we need set up, do that first. This creates the voters in firestore from bigquery
    const needsSetUp = await userNeedsSetUp(user);
    if (needsSetUp) {
      dispatch(setUserLoading(true));
      const setUpUserBackend = httpsCallable(fireFunctions, "setUpUser2023");
      await setUpUserBackend({});
    }

    // get the voters that match the user's phone number or email
    const voterDisplayRef = collection(fireDb, "voterDisplay");
    let q;
    if (user.phoneNumber) {
      q = query(
        voterDisplayRef,
        where("phones", "array-contains", user.phoneNumber),
        where("isUpToDate", "==", true), // if a voter is not up to date, it means they were removed from the latest voter list table version
      );
    } else if (user.email) {
      q = query(
        voterDisplayRef,
        where("email", "==", user.email),
        where("isUpToDate", "==", true),
      );
    }
    const querySnapshot = await getDocs(q);

    // go through all the matched voters
    const matchedVoters = [];
    let updatedSelectedVoter = null;
    querySnapshot.forEach((doc) => {
      const matchedVoter = { voterId: doc.id, ...doc.data() };
      matchedVoters.push(matchedVoter);
      // of the new docs that we fetched, if one of them is the updated version of the currently selected single voter, store that new voter info for dispatch later.
      if (doc.id === selectedVoterId) {
        updatedSelectedVoter = matchedVoter;
      }
    });
    // if there is only one voter that matches phone/email, that will be the single selected voter.
    if (matchedVoters.length === 1) {
      updatedSelectedVoter = matchedVoters[0];
    }

    // dispatch all values based on the result. dispatch all matchedVoters.
    dispatch(setMatchedAllVoters(matchedVoters));
    // dispatch the single selected voter if it was selected previously or if it is the only match
    if (updatedSelectedVoter) {
      dispatch(setMatchedSelectedVoter(updatedSelectedVoter));
    }
    // turn off the loader.
    dispatch(setUserLoading(false));
  };

  const onAuthChange = (user) => {
    // dispatch user info, whether it's null or actual user info
    dispatch(
      setUserInfo({
        uid: user?.uid,
        phone: user?.phoneNumber,
        email: user?.email,
      }),
    );

    if (user) {
      setUpAndFetchUserVoters(user).then(() => {
        // do nothing, since this function does the backend setup, the fetching, and the dispatching.
      });
    } else {
      dispatch(setMatchedAllVoters(null));
      dispatch(setMatchedSelectedVoter(null));
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(fireAuth, onAuthChange);

    // the cleanup removes the listener
    return () => unsubscribe();
  }, []);
};
