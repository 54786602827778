import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { mobileMarginVal } from "../../styleConstants";

const Wrapper = styled.div`
  padding-top: ${(props) => (props.$useMobileMargins ? "16px" : "54px")};
  padding-bottom: ${(props) => (props.$useMobileMargins ? "28px" : "54px")};

  // just eye-balled what is a good page width.
  // for mobile views, I want to set a max width to the screen width minus mobile margins.
  // This is to keep things within margins even when a slide in is open.
  max-width: min(680px, calc(100vw - ${mobileMarginVal * 2}px));
  // max-width: 70ch;
`;

const Title = styled.div`
  padding-top: 4px;
  font-size: ${(props) => (props.$useMobileMargins ? "22px" : "22px")};
  font-weight: 500;
  padding-bottom: 4px;
`;

const LastUpdateMessage = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.64);
`;

const Emphasized = styled.span`
  font-weight: 500;
  color: black;
`;

const Bo = styled.span`
  font-weight: 600;
  color: black;
`;

const SubTitle = styled.div`
  margin-top: 48px;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 20px;
`;

const TitleSmall = styled.div`
  padding-top: 48px;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: -8px;
`;

const CuA = styled.a`
  color: black;
`;

const CuAB = styled.a`
  color: black;
  font-weight: 500;
`;

const CuSec = styled.section`
  scroll-margin-top: 80px;
`;

const dateLastUpdated = "January 1, 2025";

const PrivacyPolicy = () => {
  const screenWidth = useSelector((state) => state.screenWidth.value);

  // matches with home and petition. slightly smaller cutoff than results
  const useMobileMargins = screenWidth < 540;

  return (
    <>
      <Wrapper $useMobileMargins={useMobileMargins}>
        <Title $useMobileMargins={useMobileMargins}>Privacy Policy</Title>
        <LastUpdateMessage>
          Last updated: <Emphasized>{dateLastUpdated}</Emphasized>
        </LastUpdateMessage>
        <TitleSmall>Table of Contents</TitleSmall>
        <ol>
          <li>
            <CuA href="#s1">Introduction</CuA>
          </li>
          <li>
            <CuA href="#s2">Voter Information Obtained from States</CuA>
          </li>
          <li>
            <CuA href="#s3">Information Collected on Verified Voice</CuA>
          </li>
          <li>
            <CuA href="#s4">How Information is Used</CuA>
          </li>
          <li>
            <CuA href="#s5">How Information is Shared</CuA>
          </li>
          <li>
            <CuA href="#s6">How Information is Stored</CuA>
          </li>
          <li>
            <CuA href="#s7">Data Retention</CuA>
          </li>
          <li>
            <CuA href="#s8">User Rights</CuA>
          </li>
          <li>
            <CuA href="#s9">Cookies and Browser Persistence</CuA>
          </li>
          <li>
            <CuA href="#s10">International Access</CuA>
          </li>
          <li>
            <CuA href="#s11">Links to Other Websites</CuA>
          </li>
          <li>
            <CuA href="#s12">Policy Updates</CuA>
          </li>
          <li>
            <CuA href="#s13">Contact Information</CuA>
          </li>
        </ol>
        <CuSec id="s1">
          <SubTitle>1. Introduction</SubTitle>
          At Verified Voice, your privacy is important to us. This Privacy
          Policy explains how we collect, use, store, and share your personal
          information when you use our platform. Verified Voice provides a
          secure environment for registered voters in California and Florida to
          advocate for or against political petitions.
          <br />
          <br />
          By using Verified Voice, you agree to the collection and use of your
          personal information in accordance with this Privacy Policy. If you
          have any questions or concerns, feel free to reach out to us at{" "}
          <Emphasized>info@verifiedvoice.org</Emphasized>. Your communications
          with us will be retained and used as detailed in this Privacy Policy.
          Refer to the <CuAB href="#s13">Contact Information</CuAB> section for
          more info.
        </CuSec>
        <CuSec id="s2">
          <SubTitle>2. Voter Information Obtained from States</SubTitle>
          Verified Voice legally obtains voter lists from the states of
          California and Florida. These lists include information about
          registered voters and are provided to us under state regulations.
          <br />
          <br />
          <Bo>For California:</Bo>
          <br />
          See{" "}
          <CuAB
            href="https://www.sos.ca.gov/elections/voter-registration/voter-registration-information-file-request"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </CuAB>{" "}
          for the regulations around how we receive voter data, as well as what
          information is included and excluded. Confidential voter records
          (under Elections Code{" "}
          <CuAB
            href="https://www.sos.ca.gov/registries/safe-home/laws/confidential-voter-registration-law"
            target="_blank"
            rel="noopener noreferrer"
          >
            sections 2166, 2166.5, 2166.7, and 2166.8
          </CuAB>
          ) are not included in the voter list. The voter information we receive
          typically includes:
          <ul>
            <li>Registrant ID</li>
            <li>Full name (first, middle, last, suffix, and prefix)</li>
            <li>Full address and mailing address (including zip code)</li>
            <li>Phone number(s) and email address</li>
            <li>
              Date of birth, place of birth, language, gender, and political
              party
            </li>
            <li>
              voter status ({`"Active"`} or {`"Inactive"`}), voter status
              reason, registration date, registration method code, and vote by
              mail status ({`"Permanent"`} or not)
            </li>
            <li>
              Precinct and county information (used to determine city council,
              state assembly, state house, and congressional districts)
            </li>
          </ul>
          <Bo>For Florida:</Bo>
          <br />
          Read about how we obtain Florida voter data{" "}
          <CuAB
            href="https://dos.fl.gov/elections/data-statistics/voter-registration-statistics/voter-extract-request/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </CuAB>
          . Florida voter data is generally public, although some voter data is
          kept confidential and not made available to us. You can read more
          about how{" "}
          <CuAB
            href="https://dos.fl.gov/elections/for-voters/voter-registration/voter-information-as-a-public-record/"
            target="_blank"
            rel="noopener noreferrer"
          >
            voter data is public
          </CuAB>{" "}
          in Florida. Note that confidential voter records still include the{" "}
          {"voter's"} county code, voter ID, public records exemption, gender,
          race, registration date, political party, and voter status, while
          excluding personal information like name, address, and contact
          information. The public, non-confidential voter information that is
          given to us typically includes:
          <ul>
            <li>County code and voter ID</li>
            <li>Full name (first, middle, last, and suffix)</li>
            <li>Full address and mailing address (including zip code)</li>
            <li>Phone number and email address</li>
            <li>Date of birth, gender, race, and political party</li>
            <li>
              voter status ({`"Active"`} or {`"Inactive"`}) and registration
              date
            </li>
            <li>
              Precinct and various district assignments (congressional, state
              senate, state house, county commission, and school board)
            </li>
            <li>
              Public Records Exemption ({`"Y"`} if voter record is protected and
              not included, {`"N"`} if the voter record is publicly available)
            </li>
          </ul>
        </CuSec>
        <CuSec id="s3">
          <SubTitle>3. Information Collected on Verified Voice</SubTitle>
          In addition to obtaining voter data from California and Florida,
          Verified Voice collects specific information directly from users. This
          includes:
          <ul>
            <li>
              <Bo>Phone Number or Email Address:</Bo> Collected when you sign
              in, or if you call, text, or email us.
            </li>
            <li>
              <Bo>First Name, Last Name, Date of Birth, and Zip Code:</Bo>{" "}
              Collected when you use the “Find Phone/Email on File” form on the
              /signin/help page to search for your voter status and information
              on file.
            </li>
            <li>
              <Bo>Petition Stances:</Bo> Collected when a signed-in user
              indicates support for or opposition to a petition on that
              {"petition's"} page. We record the timestamp of each inputted
              stance and maintain a full history of your stances for each
              petition.
            </li>
            <li>
              <Bo>Communication and Petition Submissions:</Bo> When you
              communicate with Verified Voice—whether for petition submissions,
              general inquiries, or other purposes—we collect and retain your
              contact information (such as email address, phone number) along
              with the contents of your message. This information is used solely
              to respond to inquiries, provide support, and review petition
              submissions in accordance with platform standards. This is
              described further in our{" "}
              <Link
                to="/about/terms-of-service"
                target="_blank"
                style={{ color: "black", fontWeight: "500" }}
              >
                Terms of Service
              </Link>
              , particularly the <Emphasized>User-Generated Content</Emphasized>{" "}
              section.
            </li>
          </ul>
        </CuSec>
        <CuSec id="s4">
          <SubTitle>4. How Information is Used</SubTitle>
          Verified Voice uses state voter data and the information collected on
          the platform to provide a secure and functional experience. {
            "Here's"
          }{" "}
          how we use the data:
          <ul>
            <li>
              <Bo>Authentication and Voter Verification:</Bo> When you sign in
              with your phone number or email, we match it against the
              California and Florida voter lists we have on file. You can then
              act (i.e. support or oppose Verified Voice petitions) as any voter
              whose registration includes the same phone number or email address
              you used to sign in.
            </li>
            <li>
              <Bo>Profile Information:</Bo> When you sign in, we display your
              voter information—specifically your authenticated phone/email,
              full name (first, last, suffix), city, district assignments (i.e.
              city council, state assembly/house, state senate, and
              congressional districts), and state—as part of your profile on
              Verified Voice. This helps you identify which voter you are acting
              as. If your phone number or email matches multiple voters in our
              voter list, you will see the full name (first, last, suffix),
              city, congressional district, and state for each matched voter. We
              also display your current stances for all petitions so you can
              view and adjust them as needed.
            </li>
            <li>
              <Bo>Voter Status and Info Lookup:</Bo> he first name, last name,
              date of birth, and zip code you provide through the “Find
              Phone/Email on File” form are used to help guide you through
              account setup. We search for and display your voter status (
              {`"Active"`} or {`"Inactive"`}) and any masked phone number(s) or
              email address(es) linked to your voter registration on file. Based
              on this information, we provide guidance on how to sign in to
              Verified Voice as a verified voter, or on how to update your voter
              registration to be able to sign in later.
            </li>
            <li>
              <Bo>Petition Support/Opposition:</Bo> When you support or oppose a
              petition, your stance is recorded and linked to your voter
              information. We aggregate these stances with others to display the
              overall support/opposition of any petition on the platform, broken
              down by geography (e.g., city council district, city, county,
              state senate district, state assembly/house district, or
              congressional district) or other voter demographics such as age,
              gender, political party, race, language, or voter status. We may
              also combine multiple demographics (e.g., gender and political
              party) for a more detailed breakdown. Because we retain timestamps
              and the history of {"voters'"} stances, we can generate these
              aggregated petition support/opposition results as they are at
              present or at a specific time in the past. This aggregated data
              publicly reflects overall petition support/opposition without
              sharing personal identifiers.
            </li>
            <li>
              <Bo>Platform Security and Integrity:</Bo> We may use your
              information internally to safeguard the platform, such as
              verifying user/voter authenticity, monitoring for suspicious
              activity, and preventing unauthorized access, to ensure the
              security and functionality of Verified Voice.
            </li>
            <li>
              <Bo>Communication:</Bo> We may use your phone number or email
              address to communicate with you for customer support or to provide
              updates regarding our platform, petitions, policies, or Terms, as
              detailed by our{" "}
              <Link
                to="/about/terms-of-service"
                target="_blank"
                style={{ color: "black", fontWeight: "500" }}
              >
                Terms of Service
              </Link>
              . This also includes notifying you if your contact information
              appears in future versions of our voter lists, should you request
              such notifications. We also rely on the contents of your messages
              to respond to inquiries and provide effective support.
            </li>
            <li>
              <Bo>Petition Submissions:</Bo> We welcome petition submissions via
              email. Upon receiving a submission, we review and evaluate it
              based on platform standards. Working with the submitting party,
              the petition may be uploaded to our platform for user
              participation. By submitting a petition, you consent to its public
              display on Verified Voice and its retention for platform
              continuity. If you wish to request edits or removal, contact us at{" "}
              <Emphasized>info@verifiedvoice.org</Emphasized>. Your
              communications with us will be retained and used as detailed in
              this Privacy Policy. Refer to the{" "}
              <CuAB href="#s13">Contact Information</CuAB> section for more
              info. While Verified Voice will consider such requests in good
              faith, final decisions regarding edits or removal are at the{" "}
              {"platform's"} sole discretion. For a full overview of petition
              submissions, please see the{" "}
              <Emphasized>User-Generate Content</Emphasized> section of our{" "}
              <Link
                to="/about/terms-of-service"
                target="_blank"
                style={{ color: "black", fontWeight: "500" }}
              >
                Terms of Service
              </Link>
              .
            </li>
            <li>
              <Bo>Petition Submission Retention:</Bo> Once a petition is
              submitted and approved, Verified Voice may retain the petition on
              the platform indefinitely, regardless of the account status of the
              submitter. This retention ensures the continuity and relevance of
              public policy discussions hosted on Verified Voice. Approved
              petitions may be reformatted and publicly displayed to facilitate
              user engagement and platform operations.
            </li>
          </ul>
        </CuSec>
        <CuSec id="s5">
          <SubTitle>5. How Information is Shared</SubTitle>
          We limit the sharing of personal information to protect your privacy.{" "}
          {"Here's"} how your information may be shared:
          <ul>
            <li>
              <Bo>Aggregated Data:</Bo> Petition stances are linked to voter
              data and combined to display aggregated totals of petition support
              and opposition on the platform. These results may be broken down
              by geography (e.g., city council district, city, county, state
              senate district, state assembly/house district, or congressional
              district) or voter demographics such as age, gender, political
              party, race, language, voter status, or other characteristics
              provided in the voter data. We may also combine multiple
              demographics (e.g., gender and political party) for a more
              detailed breakdown. By retaining timestamps and historical stance
              data, we can generate and share aggregated support/opposition
              results as they are at present or as they were at a specific time
              in the past. This aggregated data is publicly visible but does not
              include personal identifiers, such as names or contact details.
            </li>
            <li>
              <Bo>Sharing Aggregated Data with Third Parties:</Bo> In addition
              to displaying aggregated petition support and opposition results
              on the platform, we may share these same results with political
              campaigns or candidates, government offices, elected officials,
              research organizations, or other individuals or organizations. The
              data shared will be in the same aggregated and anonymized format,
              without any personal identifiers such as names or contact
              information.
            </li>
            <li>
              <Bo>Sharing Voter Information with Authenticated Users:</Bo> When
              a user signs in using their phone number or email, they are
              granted access to voter information linked to that phone number or
              email. This includes the {"voter's"} full name (first, middle,
              last, suffix), city, district assignments (i.e. city council,
              state assembly/house, state senate, and congressional districts),
              phone numbers, email address, and all of that {"voter's"} petition
              stances, including timestamps and history of stances. This
              information is shared only with the authenticated user to enable
              them to act as a verified voter on the platform.
            </li>
            <li>
              <Bo>Third Parties:</Bo> We do not share your personal information,
              including your phone number, email address, voter information, or
              specific petition stances, with third parties for marketing or
              commercial purposes. User information is stored in Google Cloud,
              an industry-standard third party service used to host our
              platform. This is outlined in the{" "}
              <CuAB href="#s6">How Information is Stored</CuAB> section.
            </li>
            <li>
              <Bo>Fraud Prevention and Legal Compliance:</Bo> We may disclose
              user information to third parties, such as law enforcement or
              investigators, to comply with legal obligations, respond to valid
              legal requests (e.g., law enforcement inquiries or court orders),
              or investigate and prevent fraudulent activities, unauthorized
              access, or security threats that could harm Verified Voice, its
              users, or the public.
            </li>
          </ul>
        </CuSec>
        <CuSec id="s6">
          <SubTitle>6. How Information is Stored</SubTitle>
          Verified Voice is hosted on the Google Cloud Platform (GCP), a secure,
          industry-standard provider for web applications and cloud data
          storage. We use services like Google Cloud Storage, BigQuery,
          Firebase, and Firestore to store and manage voter and user data.{" "}
          {"Here's"} how we handle your information:
          <ul>
            <li>
              <Bo>California and Florida Voter Lists:</Bo> Voter lists from
              California and Florida are sent by secure web link or physical CD.
              The data is processed, cleaned, and stored locally before being
              uploaded to Google Cloud Storage and Google BigQuery. Verified
              Voice queries these voter lists in BigQuery as needed, such as for
              voter verification or retrieving voter status and contact
              information.
            </li>
            <li>
              <Bo>BigQuery Logs:</Bo> Every query made to the BigQuery voter
              lists is logged internally. This includes searches for
              authenticated phone numbers or emails during voter verification,
              or for a specific {"voter's"} status and contact information. The
              log records what was searched (e.g., phone number, email, or first
              and last name, date of birth, and zip code) and the time of the
              query. Logs are retained for 6 months.
            </li>
            <li>
              <Bo>Authenticated Users:</Bo> Phone numbers and email addresses
              used for authentication are managed and stored securely through
              Firebase Auth. Firebase Auth also logs the first and most recent
              sign-in for all users.
            </li>
            <li>
              <Bo>Voter Profile Information and Petition Stances:</Bo> When a
              user signs in and is matched with a voter from the voter list, the{" "}
              {"voter's"} information—including full name (first, middle, last,
              suffix), city, zip code, county code, district assignments (i.e.
              city council, state assembly/house, state senate, and
              congressional districts), phone numbers, email address, date of
              birth, gender, race, political party, registration date, language,
              place of birth, vote by mail status, and voter status/reason—is
              copied from the BigQuery list into Firestore. That {"voter's"}{" "}
              inputted petition stances and petition stance histories are also
              stored in Firestore.
            </li>
            <li>
              <Bo>Data Access:</Bo> The Verified Voice client (user-facing
              browser interface) accesses user and voter data either through
              secure Google Cloud Functions or directly from Firestore. Strict
              security rules ensure that only users with an authenticated phone
              number or email can access voter information associated with that
              phone number or email. Data may also be accessed during internal
              monitoring or to generate reports of aggregated petition stances.
            </li>
            <li>
              <Bo>Communication Records and Petition Submissions:</Bo>{" "}
              Communication records, including petition submissions, general
              inquiries, and support requests, are stored securely in our email
              system (Gmail for email communications) and Google Voice (for
              phone and text communications). Petitions approved for inclusion
              on Verified Voice are reformatted for frontend use and hosted
              publicly on our platform through Firebase Hosting, where they are
              accessible to all site visitors and the general public.
            </li>
          </ul>
        </CuSec>
        <CuSec id="s7">
          <SubTitle>7. Data Retention</SubTitle>
          Verified Voice retains personal information for as long as necessary
          to provide our services, fulfill legal obligations, or maintain
          security and functionality on the platform. Specifically:
          <ul>
            <li>
              <Bo>User-Provided Data and Profile Information:</Bo> Information
              you provide through the platform, such as petition stances, as
              well as voter profile information copied from the voter list, may
              be retained as long as it is necessary to maintain platform
              functionality, continuity of service, and accurate petition
              records. While some data may be retained indefinitely to ensure
              these purposes, it may also be deleted if it is no longer required
              or in cases of account inactivity or account termination, as
              detailed below.
            </li>
            <li>
              <Bo>Inactive Accounts:</Bo> If an account remains inactive for a
              prolonged period, Verified Voice reserves the right to take
              actions such as terminating the account or deleting associated
              data to optimize platform resources. Data associated with inactive
              accounts may be retained or deleted in accordance with this policy
              and applicable laws. For more details about accounts and account
              inactivity, refer to the{" "}
              <Emphasized>
                User Accounts, Conduct, and Responsibilities
              </Emphasized>{" "}
              section of our{" "}
              <Link
                to="/about/terms-of-service"
                target="_blank"
                style={{ color: "black", fontWeight: "500" }}
              >
                Terms of Service
              </Link>
              .
            </li>
            <li>
              <Bo>Account Termination:</Bo> In addition to actions related to
              account inactivity, Verified Voice may delete or retain data
              associated with accounts terminated due to violations of our Terms
              of Service. For more details about account termination, please
              refer to the{" "}
              <Emphasized>Monitoring, Enforcement, and Termination</Emphasized>{" "}
              section of our{" "}
              <Link
                to="/about/terms-of-service"
                target="_blank"
                style={{ color: "black", fontWeight: "500" }}
              >
                Terms of Service
              </Link>
              .
            </li>
            <li>
              <Bo>Voter Data:</Bo> Voter lists from California and Florida are
              legally obtained and updated periodically as provided by the
              states. Verified Voice retains this data as long as necessary to
              support the verification process. Users wishing to update or
              correct voter data should contact their {"state's"} election
              authority.
            </li>
            <li>
              <Bo>Authentication Data:</Bo> Verified Voice stores your
              authenticated account information, including authenticated phone
              or email and first and most recent sign-in timestamps, in Firebase
              {"Auth's"} backend system. This data is retained while the account
              exists, but may be deleted if the account is inactive or upon user
              request.
            </li>
            <li>
              <Bo>BigQuery Logs:</Bo> Queries of our BigQuery voter lists (for
              voter verification or to find voter status and contact info on
              file), including timestamp and information searched in the query,
              are retained in internal logs for 6 months.
            </li>
            <li>
              <Bo>Communication Records and Petition Submissions:</Bo> Records
              from communications—including petition submissions, general
              inquiries, and support requests—are retained as long as necessary
              to provide responsive service, address inquiries, or maintain
              platform quality. Approved petition submissions may be retained
              indefinitely to ensure continuity of public policy discussions
              hosted on Verified Voice.
            </li>
          </ul>
          If you have any questions or would like to request the deletion of any
          personal information you provided, please contact us at{" "}
          <Emphasized>info@verifiedvoice.org</Emphasized>. Your communications
          with us will be retained and used as detailed in this Privacy Policy.
          Refer to the <CuAB href="#s13">Contact Information</CuAB> section for
          more info.
        </CuSec>
        <CuSec id="s8">
          <SubTitle>8. User Rights</SubTitle>
          At Verified Voice, we provide you with the ability to access, correct,
          or delete any of the personal information you provided through our
          platform. This does not apply to the voter data we legally obtain from
          California and Florida. Here are your rights, compliant with the
          California Consumer Privacy Act (CCPA):
          <ul>
            <li>
              <Bo>Access and Correction:</Bo> You have the right to request
              access to any personal information collected by our platform, such
              as your petition stances, and to request corrections to any
              inaccurate information you provided. To access or correct your
              data, contact us at{" "}
              <Emphasized>info@verifiedvoice.org</Emphasized>. Please note that
              corrections to voter registration information must be made through
              your {"state's"} election authority.
            </li>
            <li>
              <Bo>Deletion:</Bo> You may request the deletion of your account
              and personal information provided through Verified Voice, such as
              your petition stances. However, this does not apply to voter data
              that we receive from the state. Deleting your account data will
              remove your access to the platform, but your voter information
              will remain in our voter lists. To request account deletion,
              contact us at <Emphasized>info@verifiedvoice.org</Emphasized>. To
              request data deletion, users must provide sufficient information
              to verify their identity, such as a valid email or phone number
              associated with their account. Verified Voice reserves the right
              to delete data associated with inactive or terminated accounts in
              accordance with this Privacy Policy.
            </li>
            <li>
              <Bo>Communication Opt-Out:</Bo> You can opt out of receiving
              non-essential communications from us by contacting{" "}
              <Emphasized>info@verifiedvoice.org</Emphasized>. Note that opting
              out of non-essential communications will not affect your receipt
              of essential communications, which include updates regarding
              account security, terms, or policies. This is further outlined in
              our{" "}
              <Link
                to="/about/terms-of-service"
                target="_blank"
                style={{ color: "black", fontWeight: "500" }}
              >
                Terms of Service
              </Link>
              .
            </li>
            <li>
              <Bo>Sale of Personal Data:</Bo> Verified Voice does not sell
              personal information.
            </li>
            <li>
              <Bo>Discrimination:</Bo> You have the right to not be
              discriminated against for exercising your privacy rights.
            </li>
          </ul>
          If you have any questions or wish to exercise your rights, please
          contact us at <Emphasized>info@verifiedvoice.org</Emphasized>. Your
          communications with us will be retained and used as detailed in this
          Privacy Policy. Refer to the{" "}
          <CuAB href="#s13">Contact Information</CuAB> section for more info. To
          access, modify, or delete your official voter data, please contact
          your {"state's"} election authority.
        </CuSec>
        <CuSec id="s9">
          <SubTitle>9. Cookies and Browser Persistence</SubTitle>
          Verified Voice uses browser persistence mechanisms to ensure smooth
          user experiences and session continuity. These mechanisms help improve
          your experience by keeping you signed in and maintaining your progress
          when checking voter status. We do not use tracking cookies for
          advertising or other marketing purposes. {"Here's"} how browser
          storage and persistence on Verified Voice are used:
          <ul>
            <li>
              <Bo>Firebase Auth Session Persistence:</Bo> While you are signed
              with your phone number or email, your authenticated session, along
              with the verified phone number or email, is stored in the browser
              using Firebase Auth. Additionally, any voter information linked to
              your authenticated phone or email—such as full name (first,
              middle, last, suffix), city, district assignments (i.e. city
              council, state assembly/house, state senate, and congressional
              districts), state, phone numbers, email address, and petition
              stances (including timestamps and history of stances)—remains in
              the browser until you log out. This allows you to navigate the
              platform seamlessly as a verified registered voter. To manage
              browser storage, we use Redux Persist, a tool that works with our
              front-end framework, React.
            </li>
            <li>
              <Bo>Sign In Help Flow Persistence:</Bo> During the Sign In Help (
              {`"Find Phone/Email on File"`}) process, the information you
              provide—your first name, last name, date of birth, and zip
              code—and the results of the voter search, including voter status
              and masked contact information, are temporarily stored in the
              browser using Redux Persist. This enables us to guide you through
              the steps to sign in or update your voter registration without
              requiring repeated information entry. The data is cleared when you
              click {`"clear"`} in the voter information header or when you exit
              the flow, which includes the following paths: /signin/help,
              /signin/help/voter-info, /signin/help/update-instructions, and
              /signin/help/voter-update.
            </li>
          </ul>
          These browser-based mechanisms are used solely to enhance the
          functionality of the platform, and no data is shared or tracked for
          advertising purposes. Users can manage browser-stored data by logging
          out or clearing their browser cache.
        </CuSec>
        <CuSec id="s10">
          <SubTitle>10. International Access</SubTitle>
          Verified Voice is intended for use by voters registered in California
          and Florida. The platform is not designed for international users. If
          you access Verified Voice from outside the United States, you do so at
          your own risk and acknowledge that your data will be stored and
          processed in the U.S. By using Verified Voice, you consent to the
          transfer, storage, and processing of your data in accordance with this
          Privacy Policy and U.S. laws, which may differ from the laws in your
          country. You can read more about this in the{" "}
          <Emphasized>International Use</Emphasized> section of our{" "}
          <Link
            to="/about/terms-of-service"
            target="_blank"
            style={{ color: "black", fontWeight: "500" }}
          >
            Terms of Service
          </Link>
          .
        </CuSec>
        <CuSec id="s11">
          <SubTitle>11. Links to Other Websites</SubTitle>
          Verified Voice may contain links to external websites for
          informational purposes or for ease of access to related resources.
          Please be aware that once you leave Verified Voice, this Privacy
          Policy does not apply to external sites. Verified Voice does not
          control and is not responsible for the content, privacy practices, or
          policies of these websites. We encourage you to review the privacy
          policies of any third-party sites you visit. You can read more about
          this in the{" "}
          <Emphasized>
            Links to External Sites and Third-Party Services
          </Emphasized>{" "}
          section of our{" "}
          <Link
            to="/about/terms-of-service"
            target="_blank"
            style={{ color: "black", fontWeight: "500" }}
          >
            Terms of Service
          </Link>
          .
        </CuSec>
        <CuSec id="s12">
          <SubTitle>12. Policy Updates</SubTitle>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices, legal requirements, or other factors. When we make
          changes, we will post the revised policy on this page and update the
          “Last Updated” date at the top. The most recent version of this
          Privacy Policy is always available here at{" "}
          <Link to="/about/privacy-policy" style={{ color: "black" }}>
            verifiedvoice.org/about/privacy-policy
          </Link>
          . If the changes are significant, we may also notify you through email
          or text.
          <br />
          <br />
          By continuing to use Verified Voice after any updates to this policy,
          you agree to the terms of the revised Privacy Policy. It is your
          responsibility to periodically review this Privacy Policy to stay
          informed of updates.
        </CuSec>
        <CuSec id="s13">
          <SubTitle>13. Contact Information</SubTitle>
          If you have any questions or concerns about Verified Voice, this
          Privacy Policy, or our Terms of Service, or you wish to exercise your
          rights related to your personal information, please contact us at:
          <br />
          <br />
          <Bo>info@verifiedvoice.org</Bo>
          <br />
          <br />
          This email address serves as {"Verified Voice's"} official channel for
          receiving all communications, including general inquiries, feedback or
          suggestions, and requests related to your personal information and
          user rights.
          <br />
          <br />
          By contacting Verified Voice, you acknowledge and agree that:
          <ul>
            <li>
              Your communication may be retained in accordance with this Privacy
              Policy to address your query, improve our platform, or fulfill
              legal or operational requirements.
            </li>
            <li>
              Feedback submissions, including suggestions or ideas for improving
              the platform, as well as petition submissions, are considered
              voluntary contributions and are processed in line with the{" "}
              <Emphasized>User-Generated Content</Emphasized> section of our{" "}
              <Link
                to="/about/terms-of-service"
                target="_blank"
                style={{ color: "black", fontWeight: "500" }}
              >
                Terms of Service
              </Link>
              .
            </li>
          </ul>
          Please ensure your communication includes all relevant details to
          facilitate prompt review and response.
        </CuSec>
      </Wrapper>
    </>
  );
};

export default PrivacyPolicy;
