import styled from "styled-components";
import NativeSelect from "@mui/material/NativeSelect";
import PropTypes from "prop-types";

import { navbarHeight, subNavBarZ } from "../../styleConstants";
import { CITY_ENUMS, JDN_ENUMS, STATE_ENUMS } from "./petitionList";

const Wrapper = styled.div`
  // REMOVING this hacky change because it caused height changes when switching between jurisdictions
  // INSTEAD we set the height 1 px higher than before
  // this shifts the page content slightly up, but fixes the text showing up between the navbars during scroll.
  // margin-top: -1px;

  position: sticky;
  // position right below the main navbar, slightly covered by its 1px border.
  // top: ${navbarHeight + 1}px;
  // INSTEAD we set the height 1 px higher than before. This adjusted height blocks any visible scrolling next between navbars
  top: ${navbarHeight}px;
  background: white;

  // matching navbar
  z-index: ${subNavBarZ};

  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  padding-top: 14px;
  padding-bottom: 6px;

  font-size: 14px;
  color: rgba(0, 0, 0, 0.35);

  line-height: 14px;
`;

const JurisdictionToggle = styled.div`
  font-weight: 300;
  color: rgba(0, 0, 0, 0.35);
  margin-bottom: 3px;
`;

const JdnNational = styled.span`
  font-weight: ${(props) => (props.$isSel ? "500" : "300")};
  color: ${(props) => (props.$isSel ? "black" : "rgba(0, 0, 0, 0.35);")};
  cursor: pointer;
`;

const JdnState = styled.span`
  font-weight: ${(props) => (props.$isSel ? "500" : "300")};
  color: ${(props) => (props.$isSel ? "black" : "rgba(0, 0, 0, 0.35);")};
  cursor: pointer;
`;

const JdnCity = styled.span`
  font-weight: ${(props) => (props.$isSel ? "500" : "300")};
  color: ${(props) => (props.$isSel ? "black" : "rgba(0, 0, 0, 0.35);")};
  cursor: pointer;
`;

const ShowingMessageWrapper = styled.div`
  height: 30px;
  line-height: 30px;
`;

const PetitionsFilterBar = ({
  selectedJdn,
  selectedState,
  selectedCity,
  setSelectedJdn,
  setSelectedState,
  setSelectedCity,
}) => {
  const stateSelect = (
    <NativeSelect
      sx={{
        fontFamily: `"Inter", sans-serif`,
        fontSize: "14px",
        letterSpacing: "normal",
        fontWeight: 500,
      }}
      disableUnderline
      onChange={(event) => {
        setSelectedState(event.target.value);
      }}
      value={selectedState}
    >
      <option value={STATE_ENUMS.select}>select state</option>
      <option value={STATE_ENUMS.ca}>California</option>
      <option value={STATE_ENUMS.fl}>Florida</option>
      <option value={STATE_ENUMS.other}>other state</option>
    </NativeSelect>
  );

  const citySelect = (
    <NativeSelect
      sx={{
        fontFamily: `"Inter", sans-serif`,
        fontSize: "14px",
        letterSpacing: "normal",
        fontWeight: 500,
      }}
      disableUnderline
      onChange={(event) => {
        setSelectedCity(event.target.value);
      }}
      value={selectedCity}
    >
      <option value={CITY_ENUMS.select}>select city</option>
      <option value={CITY_ENUMS.SacramentoCA}>Sacramento, CA</option>
      <option value={CITY_ENUMS.other}>other city</option>
    </NativeSelect>
  );

  let showingMessage = <></>;
  if (selectedJdn === JDN_ENUMS.NATIONAL) {
    showingMessage = <>Showing national petitions.</>;
  } else if (selectedJdn === JDN_ENUMS.STATE) {
    showingMessage = <>Showing petions for: {stateSelect}</>;
  } else if (selectedJdn === JDN_ENUMS.CITY) {
    showingMessage = <>Showing petions for: {citySelect}</>;
  }

  return (
    <Wrapper>
      <JurisdictionToggle>
        <JdnNational
          $isSel={selectedJdn === JDN_ENUMS.NATIONAL}
          onClick={() => {
            setSelectedState(STATE_ENUMS.select);
            setSelectedCity(CITY_ENUMS.select);
            setSelectedJdn(JDN_ENUMS.NATIONAL);
          }}
        >
          National
        </JdnNational>{" "}
        |{" "}
        <JdnState
          $isSel={selectedJdn === JDN_ENUMS.STATE}
          onClick={() => {
            if (selectedJdn !== JDN_ENUMS.STATE) {
              setSelectedState(STATE_ENUMS.select);
              setSelectedCity(CITY_ENUMS.select);
              setSelectedJdn(JDN_ENUMS.STATE);
            }
          }}
        >
          State
        </JdnState>{" "}
        |{" "}
        <JdnCity
          $isSel={selectedJdn === JDN_ENUMS.CITY}
          onClick={() => {
            if (selectedJdn !== JDN_ENUMS.CITY) {
              setSelectedState(STATE_ENUMS.select);
              setSelectedCity(CITY_ENUMS.select);
              setSelectedJdn(JDN_ENUMS.CITY);
            }
          }}
        >
          City
        </JdnCity>
      </JurisdictionToggle>
      <ShowingMessageWrapper>{showingMessage}</ShowingMessageWrapper>
    </Wrapper>
  );
};

PetitionsFilterBar.propTypes = {
  selectedJdn: PropTypes.string,
  selectedState: PropTypes.string,
  selectedCity: PropTypes.string,
  setSelectedJdn: PropTypes.func,
  setSelectedState: PropTypes.func,
  setSelectedCity: PropTypes.func,
};

export default PetitionsFilterBar;
