import { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import ConditionsSlideIn from "../../Components/ConditionsSlideIn";
import { Link } from "react-router-dom";
import { mobileMarginVal } from "../../styleConstants";

const Wrapper = styled.div`
  padding-top: ${(props) => (props.$useMobileMargins ? "16px" : "54px")};
  padding-bottom: ${(props) => (props.$useMobileMargins ? "28px" : "54px")};

  // just eye-balled what is a good page width.
  // for mobile views, I want to set a max width to the screen width minus mobile margins.
  // This is to keep things within margins even when a slide in is open.
  max-width: min(680px, calc(100vw - ${mobileMarginVal * 2}px));
  // max-width: 70ch;

  overflow: ${(props) => (props.$stopScroll ? "hidden" : "auto")};
  position: ${(props) => (props.$stopScroll ? "fixed" : "")};

  // font-size: 18px;
`;

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const Title = styled.div`
  padding-top: 4px;
  font-size: ${(props) => (props.$useMobileMargins ? "20px" : "22px")};
  font-weight: 500;
  padding-bottom: 20px;
`;

const SubTitle = styled.div`
  padding-top: 48px;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 20px;
`;

const NoBold = styled.span`
  font-weight: 400;
`;

const Bold = styled.span`
  font-weight: 500;
`;

const About = () => {
  const [signInConditionsOpen, setSignInConditionsOpen] = useState(false);
  const screenWidth = useSelector((state) => state.screenWidth.value);

  // matches with home and petition. slightly smaller cutoff than results
  const useMobileMargins = screenWidth < 540;

  const userInfo = useSelector((state) => state.userInfo.value);
  const isLoggedIn = userInfo?.uid && true;

  return (
    <>
      {signInConditionsOpen && (
        <ConditionsSlideIn
          closeConditions={() => {
            setSignInConditionsOpen(false);
          }}
        />
      )}
      <Wrapper
        $useMobileMargins={useMobileMargins}
        $stopScroll={signInConditionsOpen}
      >
        <Title $useMobileMargins={useMobileMargins}>
          Verified Voice is a different kind of petition{" "}
          <NoBold>– only verified registered voters can participate.</NoBold>
        </Title>
        This means that the overall results of any petition send a clear signal
        to elected officials of true constituent preference.
        <SubTitle>Voter Verification</SubTitle>
        To verify your voter registration and voice your opinion on Verified
        Voice, you must verify the phone number or email address {`you've`}{" "}
        registered to vote with. This process only works for active California
        and Florida voters registered with an SMS-capable phone number or email
        address who meet{" "}
        <LinkButton
          onClick={() => {
            setSignInConditionsOpen(true);
          }}
        >
          these conditions
        </LinkButton>
        .
        <br />
        <br />
        Sign in as a verified voter or get help with the verification process on
        the{" "}
        {isLoggedIn ? (
          <>sign in page</>
        ) : (
          <Link to="/signin" style={{ color: "black" }}>
            sign in page
          </Link>
        )}
        . Please contact us if you need help with verification.
        <SubTitle>Contact</SubTitle>
        We aim to strengthen our democracy by making political advocacy direct
        and convenient. Please contact <Bold>info@verifiedvoice.org</Bold> for
        any questions, comments, or concerns.
        <br />
        <br />
        Verified Voice is run by Rahul Patel in Mountain View, California.
        <SubTitle>Policies</SubTitle>
        By using Verified Voice—including browsing our website, signing in,
        participating in petitions, communicating with us, or interacting with
        our platform in any way—you agree to our{" "}
        <Bold>
          <Link
            to="/about/terms-of-service"
            target="_blank"
            style={{ color: "black" }}
          >
            Terms of Service
          </Link>
        </Bold>{" "}
        and{" "}
        <Bold>
          <Link
            to="/about/privacy-policy"
            target="_blank"
            style={{ color: "black" }}
          >
            Privacy Policy
          </Link>
        </Bold>
        .
      </Wrapper>
    </>
  );
};

export default About;
