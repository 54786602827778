import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { signOut } from "firebase/auth";
import { fireAuth } from "../../../Firebase/firebase";

import NavbarMobileButton from "../../Navbar/NavbarMobileButton";
import ConditionsSlideIn from "../../ConditionsSlideIn";
import SignOutButtonDesktop from "./SignOutButtonDesktop";
import {
  desktopMarginVal,
  desktopMarginsCutoff,
  mobileMarginVal,
} from "../../../styleConstants";

const Wrapper = styled.div`
  // just eye-balled what is a good page width.
  // for mobile views, I want to set a max width to the screen width minus mobile margins.
  // This is to keep things within margins even when a slide in is open.
  max-width: min(680px, calc(100vw - ${mobileMarginVal * 2}px));
  // max-width: 70ch;

  // for the side margins
  padding-left: ${mobileMarginVal}px;
  padding-right: ${mobileMarginVal}px;
  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    padding-left: ${desktopMarginVal}px;
    padding-right: ${desktopMarginVal}px;
  }

  padding-top: 24px;
  padding-bottom: 16px;
  @media screen and (min-width: 540px) {
    padding-top: 54px;
    padding-bottom: 24px;
  }

  overflow-y: ${(props) => (props.$stopScroll ? "hidden" : "auto")};
  overflow-x: hidden;
  position: ${(props) => (props.$stopScroll ? "fixed" : "")};
`;

const Bold = styled.span`
  font-weight: 600;
`;

const NoMatchGuts = () => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo.value);
  const phone = userInfo.phone;
  const email = userInfo.email;
  const userContact = phone || email;
  const contactType = phone ? "phone number" : "email address";

  const [signInConditionsOpen, setSignInConditionsOpen] = useState(false);

  const screenWidth = useSelector((state) => state.screenWidth.value);

  // matches with home and petition. slightly smaller cutoff than results
  const useMobileMargins = screenWidth < 540;

  // if we come to no match guts, it means we've come from email redirect or after phone auth, but authenticated user is not found in voter list.
  // in this case, a simple redirect to sign in while display the no match banner helps.
  // in case of email redirect, we get rid of the long email url (good ui for user to read url in their browser), and when user signs out, don't have a partial sign in email box open. Once signing out, we will have a clean restart on sign in page.
  // in case of phone auth, this navigate should essentially do nothing, because we would have been on the sign in page and we remain here.
  useEffect(() => {
    navigate("/signin", { replace: true });
  }, []);

  return (
    <>
      {signInConditionsOpen && (
        <ConditionsSlideIn
          closeConditions={() => {
            setSignInConditionsOpen(false);
          }}
        />
      )}
      <Wrapper
        $useMobileMargins={useMobileMargins}
        $stopScroll={signInConditionsOpen}
      >
        Your verified {contactType}, {userContact}, was not found in our
        California or Florida active voter lists. For this reason, we cannot
        verify your voter registration using this {contactType}.
        <br />
        <br />
        Please sign out, and then click the{" "}
        <Bold>Find Phone/Email on File</Bold> button on the sign in page. This
        will tell you what phone number or email address you need to sign in
        with, or how to get set up to sign in to Verified Voice if needed.
        <br />
        <br />
        <br />
        {useMobileMargins ? (
          <NavbarMobileButton
            text="SIGN OUT"
            onClick={() => {
              signOut(fireAuth);
            }}
          />
        ) : (
          <SignOutButtonDesktop
            text="SIGN OUT"
            onClick={() => {
              signOut(fireAuth);
            }}
          />
        )}
        <br />
      </Wrapper>
    </>
  );
};

export default NoMatchGuts;
