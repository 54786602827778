import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { mobileMarginVal } from "../../styleConstants";

const Wrapper = styled.div`
  padding-top: ${(props) => (props.$useMobileMargins ? "16px" : "54px")};
  padding-bottom: ${(props) => (props.$useMobileMargins ? "28px" : "54px")};

  // just eye-balled what is a good page width.
  // for mobile views, I want to set a max width to the screen width minus mobile margins.
  // This is to keep things within margins even when a slide in is open.
  max-width: min(680px, calc(100vw - ${mobileMarginVal * 2}px));
  // max-width: 70ch;
`;

const Title = styled.div`
  padding-top: 4px;
  font-size: ${(props) => (props.$useMobileMargins ? "22px" : "22px")};
  font-weight: 500;
  padding-bottom: 4px;
`;

const LastUpdateMessage = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.64);
`;

const Emphasized = styled.span`
  font-weight: 500;
  color: black;
`;

const Bo = styled.span`
  font-weight: 600;
  color: black;
`;

const SubTitle = styled.div`
  margin-top: 48px;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 20px;
`;

const TitleSmall = styled.div`
  padding-top: 48px;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: -8px;
`;

const CuA = styled.a`
  color: black;
`;

const CuAB = styled.a`
  color: black;
  font-weight: 500;
`;

const CuSec = styled.section`
  scroll-margin-top: 80px;
`;

const dateLastUpdated = "January 1, 2025";

const TermsOfService = () => {
  const screenWidth = useSelector((state) => state.screenWidth.value);

  // matches with home and petition. slightly smaller cutoff than results
  const useMobileMargins = screenWidth < 540;

  return (
    <Wrapper $useMobileMargins={useMobileMargins}>
      <Title $useMobileMargins={useMobileMargins}>Terms of Service</Title>
      <LastUpdateMessage>
        Last updated: <Emphasized>{dateLastUpdated}</Emphasized>
      </LastUpdateMessage>
      <TitleSmall>Table of Contents</TitleSmall>
      <ol>
        <li>
          <CuA href="#s1">Introduction and Acceptance of Terms</CuA>
        </li>
        <li>
          <CuA href="#s2">Eligibility</CuA>
        </li>
        <li>
          <CuA href="#s3">International Use</CuA>
        </li>
        <li>
          <CuA href="#s4">User Accounts, Conduct, and Responsibilities</CuA>
        </li>
        <li>
          <CuA href="#s5">Platform Use and Limitations</CuA>
        </li>
        <li>
          <CuA href="#s6">Monitoring, Enforcement, and Termination</CuA>
        </li>
        <li>
          <CuA href="#s7">User-Generated Content (Petition Submissions)</CuA>
        </li>
        <li>
          <CuA href="#s8">Ownership and Intellectual Property</CuA>
        </li>
        <li>
          <CuA href="#s9">Data Collection and Privacy</CuA>
        </li>
        <li>
          <CuA href="#s10">Data Security</CuA>
        </li>
        <li>
          <CuA href="#s11">Notifications and Communication</CuA>
        </li>
        <li>
          <CuA href="#s12">Platform Use, Availability, and Modifications</CuA>
        </li>
        <li>
          <CuA href="#s13">Future Enhancements and Updates</CuA>
        </li>
        <li>
          <CuA href="#s14">
            Links to External Sites and Third-Party Services
          </CuA>
        </li>
        <li>
          <CuA href="#s15">Copyright Infringement and DMCA Policy</CuA>
        </li>
        <li>
          <CuA href="#s16">Limitation of Liability and Disclaimers</CuA>
        </li>
        <li>
          <CuA href="#s17">Indemnification</CuA>
        </li>
        <li>
          <CuA href="#s18">Waivers, Severability, and Assignment</CuA>
        </li>
        <li>
          <CuA href="#s19">Governing Law and Dispute Resolution</CuA>
        </li>
        <li>
          <CuA href="#s20">Contact Information</CuA>
        </li>
      </ol>
      <CuSec id="s1">
        <SubTitle>1. Introduction and Acceptance of Terms</SubTitle>
        Welcome to Verified Voice! Verified Voice is a platform that empowers
        registered voters in California and Florida to advocate for or against
        political petitions. These Terms of Service (“Terms”) govern your access
        to and use of our platform and services, including any participation in
        petitions, communication with Verified Voice, and submitting petitions
        for inclusion on the platform.
        <br />
        <br />
        By accessing or using Verified Voice, you acknowledge that you have
        read, understood, and agree to be bound by these Terms, along with our{" "}
        <Link
          to="/about/privacy-policy"
          target="_blank"
          style={{ color: "black", fontWeight: "500" }}
        >
          Privacy Policy
        </Link>
        , which is incorporated by reference. If you do not agree to these
        Terms, you should not access or use Verified Voice.
        <br />
        <br />
        Verified Voice reserves the right to update these Terms. For more
        details, see the{" "}
        <CuAB href="#s13">Future Enhancements and Updates</CuAB> section. By
        continuing to access or use Verified Voice following any updates to
        these Terms, you acknowledge and agree to the revised Terms. The most
        recent version of these Terms is always available here at{" "}
        <Link to="/about/terms-of-service" style={{ color: "black" }}>
          verifiedvoice.org/about/terms-of-service
        </Link>
        . It is your responsibility to periodically review these Terms to stay
        informed of updates.
        <br />
        <br />
        For questions, comments, or concerns regarding these Terms, please
        contact us at <Emphasized>info@verifiedvoice.org</Emphasized>. Refer to
        the <CuAB href="#s20">Contact Information</CuAB> section for how we
        handle communications.
      </CuSec>
      <CuSec id="s2">
        <SubTitle>2. Eligibility</SubTitle>
        To create an account on Verified Voice, you must be at least 18 years
        old. An account is automatically created when you sign in with your
        phone or email for the first time. By using Verified Voice, you confirm
        that you meet this age requirement.
        <br />
        <br />
        Verified Voice is designed for registered voters in California and
        Florida who meet the {"platform's"} verification criteria to participate
        in political petitions.
        <ul>
          <li>
            Users who cannot verify their voter registration through phone or
            email sign-in may access and view platform content but cannot
            actively participate in petitions.
          </li>
        </ul>
        Reasons for not being able to verify may include, but are not limited
        to:
        <ul>
          <li>Being registered in a state other than California or Florida.</li>
          <li>Not being a registered voter.</li>
          <li>Being classified as an inactive voter.</li>
          <li>
            Being registered without a phone number or email address on file.
          </li>
          <li>
            Being registered after the date of the most recent voter list
            update.
          </li>
        </ul>
        Verified Voice periodically updates its voter list and provides guidance
        during the verification process to help users register or update their
        voter registration for future participation.
        <br />
        <br />
        By accessing or using Verified Voice, you agree to use the platform in
        accordance with these Terms, regardless of your registration status or
        location.
      </CuSec>
      <CuSec id="s3">
        <SubTitle>3. International Use</SubTitle>
        Verified Voice is primarily designed for registered voters in California
        and Florida to participate in political petitions. By accessing Verified
        Voice from outside the United States, you acknowledge and agree to the
        following:
        <ol>
          <li>
            <Bo>Access to the Platform:</Bo> Certain features, like petition
            participation, are limited to verified registered voters in
            California or Florida.
          </li>
          <li>
            <Bo>Compliance with Local Laws:</Bo> You are responsible for
            ensuring that your use of Verified Voice complies with the laws and
            regulations of your location. Verified Voice disclaims any liability
            for non-compliance with such laws.
          </li>
          <li>
            <Bo>Data Processing and Privacy:</Bo> By using Verified Voice from
            outside the United States, you consent to the transfer, storage, and
            processing of your information in the United States, where data
            protection laws may differ from those in your jurisdiction. Your use
            of the platform constitutes your agreement with Verified {"Voice's"}{" "}
            <Link
              to="/about/privacy-policy"
              target="_blank"
              style={{ color: "black", fontWeight: "500" }}
            >
              Privacy Policy
            </Link>
            .
          </li>
          <li>
            <Bo>Access at Your Own Risk:</Bo> Users accessing Verified Voice
            from regions where its services are restricted or prohibited do so
            at their own risk. Verified Voice reserves the right to block or
            restrict access to the platform in certain jurisdictions to comply
            with applicable laws or operational requirements. Verified Voice
            does not make its services available in jurisdictions where doing so
            would violate local laws or regulations.
          </li>
          <li>
            <Bo>Limitations for Users Outside the U.S.:</Bo> By accessing
            Verified Voice outside the United States, you acknowledge that
            certain platform features may not function as intended, and Verified
            Voice disclaims liability for any limitations or restrictions due to
            local regulations. Verified Voice does not guarantee the{" "}
            {"platform's"}
            availability or functionality in jurisdictions outside the United
            States.
          </li>
          <li>
            <Bo>Export Controls and Legal Compliance:</Bo> By accessing Verified
            Voice, you represent and warrant that your use of the platform
            complies with all applicable laws, including U.S. export control
            laws. You agree not to use Verified Voice in violation of any
            embargoes, sanctions, or other legal restrictions imposed by the
            United States or your jurisdiction.
          </li>
          <li>
            <Bo>Jurisdictional Conflicts:</Bo> In the event of any conflict
            between these Terms and the laws of your jurisdiction, the laws of
            the United States and the State of California will prevail to the
            extent permissible.
          </li>
        </ol>
      </CuSec>
      <CuSec id="s4">
        <SubTitle>4. User Accounts, Conduct, and Responsibilities</SubTitle>
        By signing in to Verified Voice, you agree to the following terms
        regarding account creation, security, and user responsibilities:
        <br />
        <br />
        <Bo>1. Account Creation and Security</Bo>
        <ul>
          <li>
            <Bo>Account Creation:</Bo> An account is automatically created when
            you sign in to Verified Voice using your phone number or email.
            Authentication is required through secure methods such as a one-time
            password (OTP) or a secure email link.
          </li>
          <li>
            <Bo>Account Security:</Bo> You are responsible for maintaining the
            confidentiality of your sign-in credentials (OTP or secure email
            link). All activities that occur under your account are your
            responsibility. Notify us immediately at{" "}
            <Emphasized>info@verifiedvoice.org</Emphasized> if you suspect
            unauthorized access. Refer to the{" "}
            <CuAB href="#s20">Contact Information</CuAB> section for how we
            handle communications.
          </li>
        </ul>
        <Bo>2. User Responsibilities</Bo>
        <br />
        As a Verified Voice user, you agree to:
        <ul>
          <li>
            Use the platform responsibly and only for its intended purposes,
            such as voter verification (via sign-in) and petition participation.
          </li>
          <li>
            Refrain from sharing your account credentials or allowing
            unauthorized access to the platform.
          </li>
          <li>
            Avoid actions that disrupt or harm Verified Voice, its users, or the
            platform’s integrity.
          </li>
        </ul>
        <Bo>3. Account Inactivity</Bo>
        <ul>
          <li>
            <Bo>Definition of Inactivity:</Bo> An account is considered inactive
            if it has not been accessed or used for a prolonged period, as
            determined at Verified {"Voice's"} sole discretion.
          </li>
          <li>
            <Bo>Inactive Account Management:</Bo> Verified Voice reserves the
            right to:
            <ul>
              <li>
                Suspend or terminate inactive accounts to optimize platform
                resources.
              </li>
              <li>
                Retain or delete data associated with inactive accounts in
                accordance with the{" "}
                <Link
                  to="/about/privacy-policy"
                  target="_blank"
                  style={{ color: "black", fontWeight: "500" }}
                >
                  Privacy Policy
                </Link>
                .
              </li>
            </ul>
          </li>
          <li>
            <Bo>Reactivation of Accounts:</Bo> If your account is terminated due
            to inactivity, you may be required to create a new account and
            re-verify your voter registration to use Verified Voice services.
          </li>
        </ul>
      </CuSec>
      <CuSec id="s5">
        <SubTitle>5. Platform Use and Limitations</SubTitle>
        Verified Voice is designed to empower registered voters in California
        and Florida to engage with political petitions. By using the platform,
        you agree to the following guidelines:
        <br />
        <br />
        <Bo>1. Account Creation and Security</Bo>
        <br />
        Verified Voice is intended for personal, non-commercial use. Permitted
        activities include (but are not limited to):
        <ul>
          <li>Signing in and verifying your voter registration.</li>
          <li>Accessing and viewing petitions.</li>
          <li>Expressing support or opposition to petitions.</li>
          <li>Submitting petition ideas via email for potential inclusion.</li>
        </ul>
        <Bo>2. Prohibited Activities</Bo>
        <br />
        Users may not engage in activities that:
        <ul>
          <li>
            Violate any local, state, national, or international laws or
            regulations.
          </li>
          <li>Circumvent platform security measures.</li>
          <li>
            Misuse the platform for fraudulent, malicious, or harmful purposes.
          </li>
          <li>
            Use automated systems (e.g., bots or scripts) to access or interact
            with the platform.
          </li>
          <li>
            Copy, modify, distribute, or create derivative works from Verified
            Voice content without permission.
          </li>
        </ul>
        <Bo>3. Content Limitations</Bo>
        <br />
        Users may not reproduce, distribute, or create derivative works from
        Verified Voice content without express permission.
        <br />
        <br />
        <Bo>4. Platform Scope and Availability</Bo>
        <br />
        On Verified Voice, voter verification and petition participation is
        currently limited to registered voters in California and Florida. If
        Verified Voice expands to additional U.S. states, these Terms and the{" "}
        <Link
          to="/about/privacy-policy"
          target="_blank"
          style={{ color: "black" }}
        >
          Privacy Policy
        </Link>{" "}
        will be updated to reflect the expanded jurisdictions and any associated
        changes to platform features or functionality.
      </CuSec>
      <CuSec id="s6">
        <SubTitle>6. Monitoring, Enforcement, and Termination</SubTitle>
        Verified Voice is committed to maintaining a secure and functional
        platform. By using Verified Voice, you acknowledge and agree to the
        following terms related to monitoring platform activity, enforcement of
        these Terms, and account termination.
        <br />
        <br />
        <Bo>1. Monitoring</Bo>
        <br />
        Verified Voice reserves the right to monitor platform activity to ensure
        compliance with these Terms, maintain platform integrity, and address
        potential violations. Monitoring may include, but is not limited to:
        <ul>
          <li>
            Reviewing user actions, such as petition submissions and
            participation.
          </li>
          <li>Detecting unauthorized access or security threats.</li>
          <li>Investigating reported misuse or prohibited activities.</li>
        </ul>
        Monitoring is conducted in accordance with our{" "}
        <Link
          to="/about/privacy-policy"
          target="_blank"
          style={{ color: "black", fontWeight: "500" }}
        >
          Privacy Policy
        </Link>{" "}
        to protect user information and platform security. Users may report
        suspected violations by contacting us at{" "}
        <Emphasized>info@verifiedvoice.org</Emphasized> with details of the
        incident. Refer to the <CuAB href="#s20">Contact Information</CuAB>{" "}
        section for how we handle communications.
        <br />
        <br />
        <Bo>2. Enforcement Actions</Bo>
        <br />
        If Verified Voice determines that a user has violated these Terms or
        engaged in prohibited activities, we may take one or more of the
        following enforcement actions:
        <ul>
          <li>
            <Bo>Account Restrictions:</Bo> Temporarily or permanently restrict
            access to certain features or functions.
          </li>
          <li>
            <Bo>Account Suspension or Termination:</Bo> Temporarily or
            permanently suspend or terminate your account and access to the
            platform.
          </li>
          <li>
            <Bo>Legal Action:</Bo> Pursue legal remedies if necessary to address
            harm or prevent further violations.
          </li>
        </ul>
        Enforcement actions will be determined at Verified Voice’s sole
        discretion, and we are not obligated to provide prior notice for such
        actions.
        <br />
        <br />
        <Bo>3. Account Termination</Bo>
        <br />
        Verified Voice reserves the right to terminate your account and access
        to the platform under the following circumstances:
        <ul>
          <li>
            <Bo>Violations of Terms:</Bo> You engage in unauthorized access,
            misuse, or disruptive behavior.
          </li>
          <li>
            <Bo>Legal Compliance:</Bo> Termination is required to comply with
            laws, regulations, or legal processes.
          </li>
          <li>
            <Bo>Platform Integrity:</Bo> Your actions threaten the security,
            stability, or functionality of the platform.
          </li>
        </ul>
        <Bo>Upon Termination</Bo>
        <ul>
          <li>
            Your account and associated data may be restricted or removed.
          </li>
          <li>
            Verified Voice may restrict your future access to the platform.
          </li>
          <li>
            Verified Voice may cooperate with legal authorities as required.
          </li>
        </ul>
        <Bo>Appealing a Termination</Bo>
        <br />
        If your account is terminated, you may submit a written appeal to
        Verified Voice at <Emphasized>info@verifiedvoice.org</Emphasized>. Your
        appeal should include any relevant details or explanations for your
        case. Verified Voice will review appeals on a case-by-case basis and
        provide a decision within a reasonable timeframe. However, Verified
        Voice reserves the right to uphold terminations deemed necessary for
        compliance with legal requirements, platform security, or integrity.
        Refer to the <CuAB href="#s20">Contact Information</CuAB> section for
        how we handle communications.
        <br />
        <br />
        <Bo>Voluntary Account Closure</Bo>
        <br />
        If you wish to close your account voluntarily, you may contact us at{" "}
        <Emphasized>info@verifiedvoice.org</Emphasized>. Refer to the{" "}
        <CuAB href="#s20">Contact Information</CuAB> section for details on how
        we handle communications.
        <br />
        <br />
        <Bo>Obligations After Termination:</Bo>
        <br />
        Termination of services does not exempt you from any obligations
        incurred under these Terms prior to termination.
      </CuSec>
      <CuSec id="s7">
        <SubTitle>7. User-Generated Content (Petition Submissions)</SubTitle>
        Verified Voice provides a platform for users to support or oppose
        political petitions and share petition ideas for possible inclusion on
        the platform. By submitting petitions, feedback, or other content, you
        agree to the following:
        <br />
        <br />
        <Bo>1. Petition Submissions</Bo>
        <br />
        Users can email petition ideas to Verified Voice for consideration.
        Submissions are reviewed to ensure they align with Verified Voice’s
        mission and values. Verified Voice reserves the right to edit, reject,
        or remove submissions based on appropriateness, relevance, or adherence
        to platform standards.
        <br />
        <br />
        Submission does not guarantee inclusion on the platform, and Verified
        Voice is under no obligation to publish, retain, or act upon any
        submitted content.
        <br />
        <br />
        <Bo>2. Feedback Contributions</Bo>
        <br />
        Any ideas, suggestions, or feedback you provide regarding Verified
        Voice, whether submitted through email, contact forms, or other
        communication channels, are treated as voluntary contributions. By
        submitting feedback, you agree that:
        <ul>
          <li>
            Verified Voice may use, adapt, or implement your feedback without
            obligation or compensation to you.
          </li>
          <li>
            Feedback submissions are processed and retained in accordance with
            our{" "}
            <Link
              to="/about/privacy-policy"
              target="_blank"
              style={{ color: "black", fontWeight: "500" }}
            >
              Privacy Policy
            </Link>{" "}
            and the <CuAB href="#s20">Contact Information</CuAB> section of
            these Terms.
          </li>
        </ul>
        <Bo>3. Collaboration and Ownership</Bo>
        <br />
        Submitted petitions, feedback, and ideas are treated as collaborative,
        public-interest contributions. By submitting content, you acknowledge
        and agree that Verified Voice may adapt, modify, or build upon your
        submissions to further its mission.
        <br />
        <br />
        Verified Voice retains full discretion over how submissions are
        displayed, modified, or removed. Submitting an idea or petition does not
        grant you ownership, control, or influence over how the content is used
        or shared on the platform.
        <br />
        <br />
        <Bo>4. Editing, Feedback, and Removal</Bo>
        <br />
        If {"you'd"} like Verified Voice to edit, update, or take down content
        you submitted, email us at{" "}
        <Emphasized>info@verifiedvoice.org</Emphasized> with details about the
        content and your request. Verified Voice will consider such requests in
        good faith but retains sole discretion over final decisions regarding
        content moderation, presentation, and retention. Refer to the{" "}
        <CuAB href="#s20">Contact Information</CuAB> section for how we handle
        communications.
        <br />
        <br />
        <Bo>5. Content Responsibility</Bo>
        <br />
        Verified Voice does not endorse or validate the accuracy or content of
        user-submitted petitions, which remain the sole responsibility of the
        submitter. By submitting content, you confirm that:
        <ul>
          <li>
            You own the rights to the submitted content or have permission to
            share it.
          </li>
          <li>
            The content does not infringe on any intellectual property, privacy,
            or other legal rights of third parties.
          </li>
          <li>The content complies with these Terms and applicable laws.</li>
        </ul>
        <Bo>6. Platform Rights</Bo>
        <br />
        Verified Voice reserves the right to edit, aggregate, or remove
        user-submitted content at its discretion, at any time, and for any
        reason, without prior notice. Content may be modified to ensure it
        aligns with Verified {"Voice's"} mission, adheres to platform standards,
        or addresses legal requirements.
        <br />
        <br />
        Verified Voice may retain submitted content indefinitely to serve the{" "}
        {"platform's"} mission. You acknowledge that submissions are not private
        and may be shared publicly or used for promotional purposes.
      </CuSec>
      <CuSec id="s8">
        <SubTitle>8. Ownership and Intellectual Property</SubTitle>
        Verified Voice retains exclusive ownership of all content, materials,
        and features provided on the platform, including but not limited to
        text, design, graphics, and logos. You agree not to copy, distribute,
        modify, or otherwise use any Verified Voice content without express
        permission.
        <br />
        <br />
        When you submit petitions, feedback, or other content to Verified Voice,
        you grant a perpetual, irrevocable, royalty-free, worldwide license for
        Verified Voice to use, modify, adapt, reproduce, and distribute the
        content as part of the platform and related promotional activities.
        Verified Voice may edit, aggregate, or retain submissions indefinitely
        to further its mission. Submission does not grant the user any rights to
        ownership, control, or removal of the content once it has been included
        on the platform.
      </CuSec>
      <CuSec id="s9">
        <SubTitle>9. Data Collection and Privacy</SubTitle>
        Verified Voice respects your privacy and is committed to protecting your
        personal information. By using Verified Voice, you acknowledge and agree
        that your data will be collected, stored, and used in accordance with
        our{" "}
        <Link
          to="/about/privacy-policy"
          target="_blank"
          style={{ color: "black", fontWeight: "500" }}
        >
          Privacy Policy
        </Link>
        . Key points include:
        <br />
        <br />
        <Bo>1. Data Collected</Bo>
        <br />
        Verified Voice collects and retains certain information directly from
        users, including, but not limited to:
        <ul>
          <li>Contact information (e.g., phone number or email).</li>
          <li>Petition stances.</li>
          <li>
            Information provided for voter info and status lookup (e.g., name,
            date of birth, and zip code).
          </li>
        </ul>
        Verified Voice also uses voter registration data legally obtained from
        California and Florida election authorities.
        <br />
        <br />
        <Bo>2. Purposes of Data Collection:</Bo>
        <br />
        Uses of collected information include, but are not limited to:
        <ul>
          <li>Authentication, voter verification, and profile information.</li>
          <li>
            Displaying aggregated data on petition support and opposition.
          </li>
          <li>Platform communication and customer support.</li>
        </ul>
        <Bo>3. User Rights</Bo>
        <br />
        Verified Voice provides users with rights over their personal
        information in compliance with applicable laws, including the California
        Consumer Privacy Act (CCPA). To learn more about how to access, correct,
        or delete your data, please refer to our{" "}
        <Link
          to="/about/privacy-policy"
          target="_blank"
          style={{ color: "black", fontWeight: "500" }}
        >
          Privacy Policy
        </Link>
        .
        <br />
        <br />
        <Bo>4. No Sale of Personal Information:</Bo>
        <br />
        Verified Voice does not sell personal information to third parties.
        <br />
        <br />
        For comprehensive details about data collection, storage, and user
        rights, please refer to the Verified Voice{" "}
        <Link
          to="/about/privacy-policy"
          target="_blank"
          style={{ color: "black", fontWeight: "500" }}
        >
          Privacy Policy
        </Link>
        .
      </CuSec>
      <CuSec id="s10">
        <SubTitle>10. Data Security</SubTitle>
        Verified Voice takes data security seriously and implements measures to
        protect your information. By using Verified Voice, you agree to the
        following security practices:
        <ul>
          <li>
            <Bo>Secure Data Storage:</Bo> We store data using secure,
            industry-standard practices to protect your personal information
            from unauthorized access, disclosure, or alteration.
          </li>
          <li>
            <Bo>User Responsibility:</Bo> While we implement rigorous data
            security practices, users also play a role in safeguarding their
            accounts. You are responsible for maintaining the confidentiality of
            your sign-in credentials, including one-time passwords (OTPs) and
            secure email links. Do not share these details with others, and
            notify us immediately if you suspect unauthorized access to your
            account. You are responsible for securing your device and ensuring
            you use the platform in a secure environment.
          </li>
          <li>
            <Bo>No Guarantee of Absolute Security:</Bo> Despite our efforts to
            protect user information, no method of transmission over the
            internet or electronic storage is completely secure. By using
            Verified Voice, you acknowledge that while we strive to protect your
            personal information, we cannot guarantee absolute security.
          </li>
        </ul>
        For a full overview on data practices, please refer to our{" "}
        <Link
          to="/about/privacy-policy"
          target="_blank"
          style={{ color: "black", fontWeight: "500" }}
        >
          Privacy Policy
        </Link>
        .
      </CuSec>
      <CuSec id="s11">
        <SubTitle>11. Notifications and Communication</SubTitle>
        By using Verified Voice, you consent to receive communications from us,
        including:
        <ul>
          <li>
            <Bo>Platform, Petition, and Policy Updates:</Bo> Notifications about
            new features, updates or news related to petitions (including
            petition outcomes), and updates to Verified Voice’s Terms of Service
            or Privacy Policy.
          </li>
          <li>
            <Bo>Account and Voter List Notifications:</Bo> Information regarding
            your account security and, if requested, notifications when your
            contact information appears in future versions of the voter list.
          </li>
          <li>
            <Bo>Customer Support and General Engagement:</Bo> Communication to
            assist with customer support, respond to inquiries, gather feedback,
            or provide relevant information to improve your experience on
            Verified Voice.
          </li>
        </ul>
        Communications will be sent to the contact information you provide
        during sign-in or that you use to reach out to us (i.e., your phone
        number or email). If you wish to adjust your communication preferences
        or opt out of non-essential messages, please contact us at{" "}
        <Emphasized>info@verifiedvoice.org</Emphasized>. Refer to the{" "}
        <CuAB href="#s20">Contact Information</CuAB> section for how we handle
        communications. Note that opting out of non-essential communications
        will not affect your receipt of essential communications, which include
        updates regarding account security, terms, or policies.
      </CuSec>
      <CuSec id="s12">
        <SubTitle>12. Platform Use, Availability, and Modifications</SubTitle>
        Verified Voice strives to provide a reliable platform for users to
        support or oppose political petitions. However, by using Verified Voice,
        you acknowledge and agree to the following terms regarding platform
        availability and modifications:
        <br />
        <br />
        <Bo>1. As-Is Basis</Bo>
        <br />
        Verified Voice is offered on an {`"as-is" and "as-available"`} basis,
        without any warranties, whether express or implied. While we aim for
        consistent and uninterrupted service, we cannot guarantee that the
        platform will always be operational, error-free, or meet your specific
        expectations.
        <br />
        <br />
        <Bo>2. Service Limitations</Bo>
        <br />
        We reserve the right to limit, suspend, or terminate access to any part
        of the platform at any time, with or without notice, for reasons such as
        maintenance, updates, or unexpected technical issues. Verified Voice is
        not responsible for any disruptions, unavailability, or discontinuation
        of services.
        <br />
        <br />
        <Bo>3. Technical Failures</Bo>
        <br />
        Verified Voice is not liable for any losses, damages, or disruptions
        caused by:
        <ul>
          <li>
            Connectivity issues beyond our control, such as user internet
            outages or third-party hosting provider disruptions.
          </li>
          <li>
            User errors during sign-in, voter verification, or petition
            interactions.
          </li>
          <li>
            Temporary or permanent data loss due to unforeseen technical
            failures, software errors, or system maintenance.
          </li>
        </ul>
        <Bo>4. Platform Modifications</Bo>
        <br />
        Verified Voice may add, remove, or modify features, services, or content
        at its sole discretion. Changes may be made to enhance the user
        experience, improve security, or address operational needs. Your
        continued use of the platform following such changes constitutes your
        acceptance of these modifications.
        <br />
        <br />
        <Bo>5. Force Majeure</Bo>
        <br />
        Verified Voice is not liable for delays or failure in performance
        resulting from acts beyond our reasonable control, including but not
        limited to acts of God, natural disasters, pandemics, government
        actions, internet outages, or cyberattacks.
      </CuSec>
      <CuSec id="s13">
        <SubTitle>13. Future Enhancements and Updates</SubTitle>
        Verified Voice is continuously evolving to improve user experience and
        expand its offerings. We may introduce new features, services, or
        functionality, such as:
        <ul>
          <li>
            <Bo>Voter Guides:</Bo> Tools tailored to your petition preferences,
            providing personalized guidance on upcoming elections or petitions.
          </li>
          <li>
            <Bo>Social Networking Features:</Bo> Options to connect with
            friends, view their petition activities, or collaborate on advocacy
            efforts.
          </li>
          <li>
            <Bo>Geographic Expansion:</Bo> Support for registered voters in
            states beyond California and Florida.
          </li>
          <li>
            <Bo>Advocacy and Petition Collaboration:</Bo> Features enabling
            users to interact and collaborate, both on and off the platform, for
            advocacy or petition drafting.
          </li>
        </ul>
        <Bo>Applicability of Terms to New Features</Bo>
        <br />
        All new features or services will be subject to these Terms of Service,
        as updated at the time of their release. By continuing to use Verified
        Voice after such enhancements are launched, you agree to any additional
        terms and conditions that may apply to those features.
        <br />
        <br />
        <Bo>Updates to the Terms of Service</Bo>
        <br />
        Verified Voice reserves the right to modify these Terms of Service to
        reflect new features, operational needs, or legal requirements. Updates
        will be effective immediately upon posting, as indicated by the{" "}
        {`"Last Updated"`} date on the top of this page. For significant
        changes, we may notify you via email or text. Your continued use of
        Verified Voice after updates are posted constitutes acceptance of the
        revised Terms. It is your responsibility to periodically check these
        Terms for updates.
      </CuSec>
      <CuSec id="s14">
        <SubTitle>
          14. Links to External Sites and Third-Party Services
        </SubTitle>
        Verified Voice may contain links to external websites or integrate with
        third-party services for added functionality. By using Verified Voice,
        you acknowledge and agree to the following terms regarding external
        sites and third-party services:
        <br />
        <br />
        <Bo>1. External Links</Bo>
        <br />
        Verified Voice may provide links to external websites for user
        convenience or informational purposes. These external sites are not
        operated, maintained, or controlled by Verified Voice. We do not
        endorse, guarantee, or make any representations about the content,
        accuracy, availability, or practices of these external sites.
        <ul>
          <li>Accessing external websites is at your own risk.</li>
          <li>
            Verified Voice is not responsible for any damages, losses, or
            liabilities arising from your use of or reliance on external
            websites.
          </li>
          <li>
            We encourage you to review the privacy policies and terms of service
            of any external websites you visit.
          </li>
        </ul>
        <Bo>2. Third-Party Services</Bo>
        <br />
        Verified Voice may integrate with or rely on third-party services to
        facilitate platform functionalities, such as user authentication, data
        processing, or hosting. By using Verified Voice, you understand and
        agree to the following:
        <ul>
          <li>
            Third-party providers may access or process certain information
            necessary to perform their services.
          </li>
          <li>
            Verified Voice takes reasonable measures to select secure and
            reliable service providers but cannot guarantee their practices,
            policies, or actions.
          </li>
          <li>
            Use of third-party services is subject to the terms and policies of
            the respective providers, and Verified Voice disclaims any liability
            for issues arising from such services, including but not limited to
            data breaches, service outages, or unauthorized use of your
            information.
          </li>
        </ul>
        Verified Voice is hosted, and user information is stored, using secure
        Google Cloud infrastructure. For detailed information on how user data
        is collected, stored, and interacts with third-party services like
        Google Cloud, please refer to our{" "}
        <Link
          to="/about/privacy-policy"
          target="_blank"
          style={{ color: "black", fontWeight: "500" }}
        >
          Privacy Policy
        </Link>
        .
      </CuSec>
      <CuSec id="s15">
        <SubTitle>15. Copyright Infringement and DMCA Policy</SubTitle>
        Verified Voice respects the intellectual property rights of others and
        complies with the Digital Millennium Copyright Act (DMCA) regarding
        copyright infringement. If you believe content on Verified Voice
        infringes your copyright, please notify us at{" "}
        <Emphasized>info@verifiedvoice.org</Emphasized>. Refer to the{" "}
        <CuAB href="#s20">Contact Information</CuAB> section for how we handle
        communications. To ensure we can process your request, your notice
        should include:
        <ol>
          <li>A description of the copyrighted work you claim is infringed.</li>
          <li>
            A description of where the infringing material is located on
            Verified Voice.
          </li>
          <li>
            Your contact information (name, address, email, and phone number).
          </li>
          <li>
            A statement affirming that you believe in good faith that the use of
            the material is unauthorized by the copyright owner, its agent, or
            the law.
          </li>
          <li>
            A statement under penalty of perjury that the information in your
            notice is accurate and that you are the copyright owner or
            authorized to act on the {"owner's"} behalf.
          </li>
          <li>Your electronic or physical signature.</li>
        </ol>
        Verified Voice may remove or restrict access to content alleged to
        infringe copyright and will make good-faith efforts to notify the user
        who submitted the content if a takedown occurs.
        <br />
        <br />
        <Bo>Dispute Process for Copyright-Related Removals</Bo>
        <br />
        If Verified Voice removes content you submitted in response to a
        copyright infringement complaint, you may submit a counter-notification
        if you believe the content was wrongly removed. A copy of your
        counter-notification may be forwarded to the original complainant. To
        submit a counter-notification, please include:
        <ol>
          <li>
            Identification of the removed content and where it appeared before
            removal.
          </li>
          <li>
            A statement under penalty of perjury that you have a good-faith
            belief the content was removed or disabled due to mistake or
            misidentification.
          </li>
          <li>Your name, address, phone number, and email address.</li>
          <li>
            A statement consenting to the jurisdiction of the federal court in
            your district (or in the district where Verified Voice is located,
            if you reside outside the U.S.) and agreeing to accept service of
            process from the original complainant.
          </li>
          <li>Your electronic or physical signature.</li>
        </ol>
      </CuSec>
      <CuSec id="s16">
        <SubTitle>16. Limitation of Liability and Disclaimers</SubTitle>
        Verified Voice provides its platform, services, and content with the
        following disclaimers and limitations of liability. By using Verified
        Voice, you agree to these terms:
        <br />
        <br />
        <Bo>1. No Warranty</Bo>
        <br />
        Verified Voice disclaims all warranties, whether express or implied,
        including but not limited to implied warranties of merchantability,
        fitness for a particular purpose, and non-infringement. We do not
        guarantee that the platform will be free from interruptions, errors, or
        security vulnerabilities.
        <br />
        <br />
        <Bo>2. Limitation of Liability</Bo>
        <br />
        To the fullest extent permitted by law, Verified Voice, its affiliates,
        and its service providers will not be liable for any indirect,
        incidental, consequential, or punitive damages arising from or relating
        to your use of the platform. This includes, but is not limited to, loss
        of data, loss of profits, or service interruptions. The total liability
        of Verified Voice for any claims related to the platform will not exceed
        the amount you paid, if any, to access the platform.
        <br />
        <br />
        Verified Voice uses third-party services for specific functionalities,
        including hosting and data processing. While we take reasonable measures
        to select secure and reliable service providers, Verified Voice is not
        liable for breaches, data loss, or service interruptions caused by these
        third parties. Users acknowledge and accept the inherent risks
        associated with third-party services.
        <br />
        <br />
        <Bo>
          3. No Responsibility for Petition Outcomes or Accuracy of Content
        </Bo>
        <br />
        Verified Voice provides a platform for users to participate in political
        petitions. Verified Voice does not actively validate or verify the
        content of petitions for accuracy or legal compliance and does not
        guarantee:
        <ul>
          <li>
            The accuracy, reliability, or completeness of any petition content.
          </li>
          <li>The adoption, endorsement, or implementation of any petition.</li>
        </ul>
        Petition results and content are intended for informational purposes
        only and depend solely on user participation and external processes
        beyond Verified {"Voice's"} control. Verified Voice is not a legal,
        political, or professional advisor, and users should independently
        verify the content and context of petitions. Your use of Verified Voice
        and reliance on its content is at your own risk.
        <br />
        <br />
        <Bo>4. Third-Party Interactions</Bo>
        <br />
        Verified Voice is not responsible for third-party content, policies, or
        services. If you interact with external websites or third-party services
        linked to Verified Voice, you do so at your own risk and under the terms
        of the respective providers.
      </CuSec>
      <CuSec id="s17">
        <SubTitle>17. Indemnification</SubTitle>
        By using Verified Voice, you agree to indemnify, defend, and hold
        harmless Verified Voice, its owner, affiliates, service providers, and
        employees from and against any claims, liabilities, damages, losses, or
        expenses, including but not limited to reasonable {"attorneys'"} fees,
        court costs, and platform-related operational costs, arising out of or
        in connection with:
        <br />
        <br />
        <Bo>1. Your Use of the Platform:</Bo>
        <br />
        Any use of Verified Voice, including but not limited to petition
        submissions, interactions with petitions or platform content, or misuse
        of platform features.
        <br />
        <br />
        <Bo>2. User Submissions:</Bo>
        <br />
        You are solely responsible for any petition stances, petition
        submissions, or other content you input or submit on Verified Voice.
        Verified Voice does not assume responsibility for the content of
        user-submitted petitions or petition stances.
        <br />
        <br />
        <Bo>3. Violation of Terms:</Bo>
        <br />
        Any violation of these Terms of Service by you, including but not
        limited to unauthorized access, misuse of the platform, or actions that
        threaten the security, stability, or integrity of Verified Voice.
        <br />
        <br />
        <Bo>4. Infringement of Rights:</Bo>
        <br />
        Any claim that your use of Verified Voice or the content you submit
        infringes upon the intellectual property, privacy, or other legal rights
        of a third party.
        <br />
        <br />
        <Bo>5. Third-Party Interactions:</Bo>
        <br />
        Any interactions with external websites or third-party services linked
        to or integrated with Verified Voice, including but not limited to
        disputes arising from their terms, policies, actions, or failures.
        <br />
        <br />
        <Bo>6. Breach of Representations or Warranties:</Bo>
        <br />
        Any breach of representations, warranties, or promises you make under
        these Terms, including the accuracy and legality of content you submit
        or share on Verified Voice.
        <br />
        <br />
        You further agree to cover the costs of responding to claims, disputes,
        or investigations that arise from your actions on the platform,
        including but not limited to:
        <ul>
          <li>
            <Bo>Legal Fees:</Bo> Costs incurred to defend or resolve claims
            related to your use of Verified Voice.
          </li>
          <li>
            <Bo>Administrative Costs:</Bo> Costs associated with investigating,
            addressing, or responding to claims or disputes.
          </li>
          <li>
            <Bo>Technical Expenses:</Bo> Expenses arising from platform
            maintenance, security measures, or recovery efforts necessitated by
            your actions.
          </li>
        </ul>
        This indemnification obligation applies to both direct and indirect
        claims and liabilities, survives the termination of your account or your
        use of Verified Voice, and includes claims brought by third parties or
        other users.
      </CuSec>
      <CuSec id="s18">
        <SubTitle>18. Waivers, Severability, and Assignment</SubTitle>
        <Bo>Waivers</Bo>
        <br />
        The failure of Verified Voice to enforce any provision of these Terms
        shall not be construed as a waiver of that provision or any other
        provision. A waiver of any term or provision in one instance shall not
        constitute a waiver of such term or provision in any other instance.
        <br />
        <br />
        <Bo>Severability</Bo>
        <br />
        If any part of these Terms is found to be invalid or unenforceable by a
        court of competent jurisdiction, that provision will be enforced to the
        maximum extent permissible, and the other provisions of these Terms will
        remain in full force and effect.
        <br />
        <br />
        <Bo>Assignment</Bo>
        <br />
        Verified Voice may assign or transfer its rights and obligations under
        these Terms without restriction and without notice to users. You may not
        assign or transfer your rights or obligations under these Terms to any
        third party without Verified {"Voice's"} prior written consent.
      </CuSec>
      <CuSec id="s19">
        <SubTitle>19. Governing Law and Dispute Resolution</SubTitle>
        These Terms of Service, along with any disputes arising from or related
        to your use of Verified Voice, will be governed by and interpreted in
        accordance with the laws of the State of California, without regard to
        its conflict of law provisions.
        <br />
        <br />
        <Bo>Agreement to Resolve Disputes Through Arbitration</Bo>
        <br />
        By using Verified Voice, you agree that any disputes arising from these
        Terms or your use of the platform will be resolved exclusively through
        binding arbitration in California, following the rules of the American
        Arbitration Association. This means:
        <ul>
          <li>
            <Bo>Individual Arbitration Only:</Bo> You waive your right to bring
            or participate in any class action, class arbitration, or other
            representative action or proceeding. All disputes must be resolved
            on an individual basis.
          </li>
          <li>
            <Bo>No Jury Trial:</Bo> You waive your right to pursue disputes in
            court or participate in a jury trial, except as permitted below.
          </li>
        </ul>
        <Bo>Exceptions to Arbitration</Bo>
        <br />
        Verified Voice reserves the right to seek injunctive or other equitable
        relief in a court of competent jurisdiction for the following
        situations:
        <ul>
          <li>
            To prevent unauthorized access, misuse, or infringement of Verified
            {"Voice's"} intellectual property rights or proprietary information.
          </li>
          <li>
            To address any emergency situation where immediate relief is
            necessary to protect Verified Voice, its users, or its
            infrastructure.
          </li>
        </ul>
        <Bo>Limitation on Claims</Bo>
        <br />
        You agree that any claim or cause of action arising out of or related to
        your use of Verified Voice or these Terms must be filed within one (1)
        year after such claim or cause of action arose. Failure to file within
        this timeframe will result in the permanent barring of such claims.
        <br />
        <br />
        This limitation on claims does not apply in jurisdictions where such
        limitations are prohibited by law. In such cases, the timeframe will be
        the minimum allowed under applicable law.
        <br />
        <br />
        <Bo>Acknowledgment of Terms</Bo>
        <br />
        By using Verified Voice, you acknowledge that you have read, understood,
        and agree to be bound by the above terms regarding dispute resolution,
        including the waiver of class or representative actions.
      </CuSec>
      <CuSec id="s20">
        <SubTitle>20. Contact Information</SubTitle>
        If you have questions, feedback, or concerns about Verified Voice, these
        Terms of Service, or our{" "}
        <Link
          to="/about/privacy-policy"
          target="_blank"
          style={{ color: "black", fontWeight: "500" }}
        >
          Privacy Policy
        </Link>{" "}
        please contact us at:
        <br />
        <br />
        <Bo>info@verifiedvoice.org</Bo>
        <br />
        <br />
        This email address serves as Verified {"Voice's"} official channel for
        receiving all communications, including general inquiries, feedback,
        legal notices, and appeals regarding account termination or other
        matters.
        <br />
        <br />
        By contacting Verified Voice, you acknowledge and agree that:
        <ul>
          <li>
            Your communication may be retained in accordance with our{" "}
            <Link
              to="/about/privacy-policy"
              target="_blank"
              style={{ color: "black", fontWeight: "500" }}
            >
              Privacy Policy
            </Link>{" "}
            to address your query, improve our platform, or fulfill legal or
            operational requirements.
          </li>
          <li>
            Feedback submissions, including suggestions or ideas for improving
            the platform, as well as petition submissions, are considered
            voluntary contributions and are processed in line with the{" "}
            <CuAB href="#s7">User-Generated Content</CuAB> section of these
            Terms.
          </li>
        </ul>
        For legal notices or appeals, please ensure your communication includes
        all relevant details to facilitate prompt review and response.
      </CuSec>
    </Wrapper>
  );
};

export default TermsOfService;
