// import { useState } from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import PetitionsFilterBar from "./PetitionsFilterBar";
import { useState } from "react";
import {
  CITY_ENUMS,
  JDN_ENUMS,
  STATE_ENUMS,
  CITY_DICT,
  allPetitions,
} from "./petitionList";
import { mobileMarginVal } from "../../styleConstants";

const Wrapper = styled.div`
  margin-top: 22px;
  margin-bottom: 24px;

  // just eye-balled what is a good page width.
  // for mobile views, I want to set a max width to the screen width minus mobile margins.
  // This is to keep things within margins even when a slide in is open.
  max-width: min(680px, calc(100vw - ${mobileMarginVal * 2}px));
  // max-width: 70ch;

  @media screen and (min-width: 540px) {
    margin-top: 26px;
    margin-bottom: 32px;
  }
`;

const PetitionTile = styled.div`
  font-weight: 500;
  font-size: 18px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 4px;
  margin-top: 12px;
  color: black;

  // to not make it too wide on desktop view.
  max-width: 814px;

  cursor: pointer;
  &:hover {
    border: 1px solid black;
  }
`;

const SelectInstructions = styled.div`
  margin-bottom: 24px;
`;

const Bold = styled.span`
  font-weight: 500;
`;

const SubNote = styled.div`
  margin-top: 6px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 16px;
  font-weight: 400;
`;

const SubmissionWrapper = styled.div`
  margin-top: 28px;
`;

const TermsAgreement = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
`;

const Petitions = () => {
  const [selectedJdn, setSelectedJdn] = useState(JDN_ENUMS.NATIONAL);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  // only set this to true if we are displaying email message
  let displayTerms = false;

  const petitionList = allPetitions.filter((petition) => {
    // only show petitions that are the selected jurisdiction AND specific location
    const pJdnInfo = petition.jurisdiction;
    // petition is of selected jurisdiction: national, state, or city
    const jdnMatch = selectedJdn === pJdnInfo.jdn;
    let locationMatch = false;
    // checking location match. i.e. that state is specificly CA or FL, or that city is Sacramento.
    if (selectedJdn === JDN_ENUMS.NATIONAL) {
      locationMatch = true;
    } else if (selectedJdn === JDN_ENUMS.STATE) {
      locationMatch = pJdnInfo.state === selectedState;
    } else if (selectedJdn === JDN_ENUMS.CITY) {
      locationMatch = pJdnInfo.city === selectedCity;
    }

    // include the petition if both jurisdiction and lcoation match.
    return jdnMatch && locationMatch;
  });

  // these guts are the guts if there is at least one petition present after filtering the list.
  const petitionsPresentGuts = (
    <>
      <SelectInstructions>
        Select a petition to view it fully and voice your opinion:
      </SelectInstructions>
      {petitionList.map((petition) => (
        <NavLink
          key={petition.url}
          to={`/petitions/${petition.url}`}
          style={{ textDecoration: "none" }}
        >
          <PetitionTile>
            {petition.uiTitle}
            <SubNote>{petition.tileDescription}</SubNote>
          </PetitionTile>
        </NavLink>
      ))}
      <SubmissionWrapper>
        Email us at <Bold>info@verifiedvoice.org</Bold> to add your own petition
        to Verified Voice.
      </SubmissionWrapper>
    </>
  );

  let petitionGuts = <></>;
  if (
    // if I have to select the state or city, display no guts yet
    (selectedJdn === JDN_ENUMS.STATE && selectedState === STATE_ENUMS.select) ||
    (selectedJdn === JDN_ENUMS.CITY && selectedCity === CITY_ENUMS.select)
  ) {
    petitionGuts = <></>;
    displayTerms = false;
  } else if (
    // if "other state", say we only support CA, FL
    selectedJdn === JDN_ENUMS.STATE &&
    selectedState === STATE_ENUMS.other
  ) {
    petitionGuts = (
      <>
        Verified Voice currently only supports state petitions for California
        and Florida. Please reach out to us for any questions or concerns:{" "}
        <Bold>info@verifiedvoice.org</Bold>.
      </>
    );
    displayTerms = true;
  } else if (
    // if "other city", say contact us to add your city.
    selectedJdn === JDN_ENUMS.CITY &&
    selectedCity === CITY_ENUMS.other
  ) {
    petitionGuts = (
      <>
        Verified Voice supports city petitions for California and Florida
        cities. Email us at <Bold>info@verifiedvoice.org</Bold> to add petitions
        for your city.
      </>
    );
    displayTerms = true;
  } else {
    // this means we've selected "national", "CA", "FL", or some added city
    if (petitionList.length <= 0) {
      let noPetitionsMessage = "There are no petitions.";
      if (selectedJdn === JDN_ENUMS.NATIONAL) {
        noPetitionsMessage = "There are no national petitions.";
      } else if (selectedJdn === JDN_ENUMS.STATE) {
        noPetitionsMessage = `There are currently no state petitions for ${
          selectedState === STATE_ENUMS.ca ? "California" : "Florida"
        }.`;
      } else if (selectedJdn === JDN_ENUMS.CITY) {
        noPetitionsMessage = `There are currently no petitions for ${
          CITY_DICT[selectedCity].fullName || "this city"
        }.`;
      }
      petitionGuts = (
        <>
          {noPetitionsMessage} Email us at <Bold>info@verifiedvoice.org</Bold>{" "}
          to add your own petition.
        </>
      );
      displayTerms = true;
    } else {
      petitionGuts = petitionsPresentGuts;
      displayTerms = true;
    }
  }

  return (
    <>
      <PetitionsFilterBar
        selectedJdn={selectedJdn}
        selectedState={selectedState}
        selectedCity={selectedCity}
        setSelectedJdn={setSelectedJdn}
        setSelectedState={setSelectedState}
        setSelectedCity={setSelectedCity}
      />
      <Wrapper>
        {petitionGuts}
        {displayTerms && (
          <TermsAgreement>
            By communicating with us, including to submit petitions, you agree
            to our{" "}
            <Link
              to="/about/terms-of-service"
              target="_blank"
              style={{ color: "rgba(0, 0, 0, 0.5)" }}
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              to="/about/privacy-policy"
              target="_blank"
              style={{ color: "rgba(0, 0, 0, 0.5)" }}
            >
              Privacy Policy
            </Link>
            .
          </TermsAgreement>
        )}
      </Wrapper>
    </>
  );
};

export default Petitions;
