import styled from "styled-components";
import HomeButton from "./HomeButton";
import { Link, useNavigate } from "react-router-dom";
import HomeButtonSecondary from "./HomeButtonSecondary";

const Wrapper = styled.div`
  padding-top: 24px;
  padding-bottom: 16px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const SubTitle = styled.div`
  font-size: 20px;
`;

const ButtonWrapper = styled.div`
  padding-top: 10px;
  width: 100%;
  display: flex;
`;

const SubNote = styled.div`
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;
`;

const TermsAgreement = styled.div`
  margin-top: 14px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
`;

const HomeMobile = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Title>Verified Voice</Title>
      <SubTitle>Online petitions for registered voters.</SubTitle>
      <br />
      <br />
      <br />
      Participate by signing in as a verified registered voter:
      <ButtonWrapper>
        <HomeButton
          text="SIGN IN"
          onClick={() => {
            navigate("/signin");
          }}
        />
      </ButtonWrapper>
      <SubNote>Via phone or email verification.</SubNote>
      <br />
      <br />
      <br />
      <br />
      <ButtonWrapper>
        <HomeButtonSecondary
          text="Browse Petitions"
          onClick={() => {
            navigate("/petitions");
          }}
        />
      </ButtonWrapper>
      <ButtonWrapper>
        <HomeButtonSecondary
          text="About Verified Voice"
          onClick={() => {
            navigate("/about");
          }}
        />
      </ButtonWrapper>
      <TermsAgreement>
        By browsing this website or engaging with our platform, you agree to our{" "}
        <Link
          to="/about/terms-of-service"
          target="_blank"
          style={{ color: "rgba(0, 0, 0, 0.5)" }}
        >
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link
          to="/about/privacy-policy"
          target="_blank"
          style={{ color: "rgba(0, 0, 0, 0.5)" }}
        >
          Privacy Policy
        </Link>
        .
      </TermsAgreement>
    </Wrapper>
  );
};

export default HomeMobile;
