import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { fireDb, fireFunctions } from "../../../../Firebase/firebase";
import { doc, getDoc } from "firebase/firestore/lite";
import { setMatchedSelectedVoter } from "../../../../Redux/matchedSelectedVoterSlice";
import NavbarMobileButton from "../../../../Components/Navbar/NavbarMobileButton";
import PetitionButton from "./PetitionButton";
import {
  desktopMarginVal,
  desktopMarginsCutoff,
  mobileMarginVal,
  overlayZ,
} from "../../../../styleConstants";
import ConfirmationOverlay from "./ConfirmationOverlay";

const StickyBottom = styled.div`
  padding-top: 6px;
  text-align: center;

  position: fixed;
  bottom: 0;
  background: white;
  padding-bottom: 6px;
  border-top: 1px solid #e5e5e5;
  font-size: 14px;

  // the desktop height of everything on single line when signed in.
  min-height: 106px;

  // this is for mobile margin.
  width: calc(100% - ${2 * mobileMarginVal}px);

  // desktop margin for screen widths at the cutoff or greater
  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    width: calc(100% - ${2 * desktopMarginVal}px);
    padding-bottom: 8px;
  }
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const SignatoryName = styled.div`
  color: rgba(0, 0, 0, 0.25);
`;

const BoldDeclaration = styled.div`
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 4px;
  padding-bottom: 6px;
  justify-content: center;
`;

const ButtonSpace = styled.div`
  min-width: 8px;
`;

const SignInInstructions = styled.div`
  padding-top: 15px;
  padding-bottom: 8px;
`;

const SignInButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const DisableInteraction = styled.div`
  position: fixed;
  width: 100%;
  height: calc(100% - ${(props) => props.$actionBarHeight}px);
  top: 0;
  left: 0;

  // needs to be in front than navbar and navigation menus to gray out/disable them
  z-index: ${overlayZ};
  background: rgba(255, 255, 255, 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
`;

const TermsAgreement = styled.div`
  color: rgba(0, 0, 0, 0.35);
  font-size: 12px;
`;

const PetitionActionBar = ({
  setActionBarHeight,
  actionBarHeight,
  petitionInfo,
}) => {
  // we want the parent petition component to know the height of this actionBar component, so it can set it's bottom margin accordingly.
  const actionBarRef = useRef(null);
  useEffect(() => {
    // setting the total height, including the 1px border
    const heightWithBorder = actionBarRef.current.clientHeight + 1;
    setActionBarHeight(heightWithBorder);
  }, [actionBarRef]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [stanceWriteLoading, setStanceWriteLoading] = useState(false);
  const [showConfirmationOverlay, setshowConfirmationOverlay] = useState(false);

  // get the selected voter info.
  const selectedVoter = useSelector(
    (state) => state.matchedSelectedVoter.value,
  );

  // we want to display the name, and city.
  const voterName = `${selectedVoter?.first || ""} ${
    selectedVoter?.last || ""
  } ${selectedVoter?.suffix || ""}`;
  const voterState = (selectedVoter?.state || "").toUpperCase();
  const voterCity = (
    <>
      {selectedVoter?.city || ""}, {voterState}{" "}
      <NoWrap>
        ({voterState}-{selectedVoter?.district || ""})
      </NoWrap>
    </>
  );

  // want to display the right stance for specific petition.
  const allStances = selectedVoter?.stances; // this may be null for a new voter.
  const voterStance = allStances
    ? allStances[petitionInfo.fireDbPath]?.currentStance?.stance || ""
    : ""; // null voterStance will be "" to allow for upperCase string function.
  const voterDocId = selectedVoter?.voterId;

  const writeStance = async (newStance) => {
    const writeStanceBackend = httpsCallable(fireFunctions, "writeStance");
    setStanceWriteLoading(true);
    await writeStanceBackend({
      stance: newStance,
      voterId: voterDocId,
      fireDbPetition: petitionInfo.fireDbPath, // so we write to this specific petition
    });
    const voterDisplayDocRef = doc(fireDb, "voterDisplay", voterDocId);
    const voterDisplayDoc = await getDoc(voterDisplayDocRef);
    if (voterDisplayDoc.exists()) {
      dispatch(
        setMatchedSelectedVoter({
          voterId: voterDisplayDoc.id,
          ...voterDisplayDoc.data(),
        }),
      );
    }
    setStanceWriteLoading(false);
  };

  return (
    <>
      {stanceWriteLoading && (
        <DisableInteraction $actionBarHeight={actionBarHeight} />
      )}
      {showConfirmationOverlay && (
        <ConfirmationOverlay
          closeModal={() => {
            setshowConfirmationOverlay(false);
          }}
          petitionName={petitionInfo.uiTitle}
        />
      )}
      <StickyBottom ref={actionBarRef}>
        {selectedVoter ? (
          <>
            <SignatoryName>
              {voterName} of {voterCity}
            </SignatoryName>
            <BoldDeclaration>
              I am in favor of this policy proposal:
            </BoldDeclaration>
            <ButtonWrapper>
              {stanceWriteLoading ? (
                <TailSpin color="black" height={30} width={30} />
              ) : (
                <>
                  <PetitionButton
                    text={"YES"}
                    isActive={voterStance.toUpperCase() === "YES"}
                    onClick={() => {
                      writeStance("YES").then(() => {
                        setshowConfirmationOverlay(true);
                      });
                    }}
                  />
                  <ButtonSpace />
                  <PetitionButton
                    text={"NO"}
                    isActive={voterStance.toUpperCase() === "NO"}
                    onClick={() => {
                      writeStance("NO").then(() => {
                        setshowConfirmationOverlay(true);
                      });
                    }}
                  />
                </>
              )}
            </ButtonWrapper>
            {!stanceWriteLoading && (
              <TermsAgreement>
                By submitting your stance, you agree to our{" "}
                <Link
                  to="/about/terms-of-service"
                  target="_blank"
                  style={{ color: "rgba(0, 0, 0, 0.35)" }}
                >
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link
                  to="/about/privacy-policy"
                  target="_blank"
                  style={{ color: "rgba(0, 0, 0, 0.35)" }}
                >
                  Privacy Policy
                </Link>
                .
              </TermsAgreement>
            )}
          </>
        ) : (
          <>
            <SignInInstructions>
              Sign in to voice your opinion:
            </SignInInstructions>
            <SignInButtonWrapper>
              <NavbarMobileButton
                text={"SIGN IN"}
                onClick={() => {
                  navigate("/signin");
                }}
              />
            </SignInButtonWrapper>
          </>
        )}
      </StickyBottom>
    </>
  );
};

PetitionActionBar.propTypes = {
  setActionBarHeight: PropTypes.func,
  actionBarHeight: PropTypes.number,
  petitionInfo: PropTypes.object,
};

export default PetitionActionBar;
